/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICreateNewsLocal,
  INewsDeleteLocal,
  INewsLocal,
} from '../../libs/apis/news/types';
import { ICreateNewsState, INewsState } from './types';
import { apiSdk } from '../../libs/apis';

export const getAllNews = createAsyncThunk(
  '/admin/news',
  async (news: INewsLocal, { rejectWithValue }) => {
    try {
      const data: INewsState = await apiSdk.newsApis.getAllNews(news);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const createNews = createAsyncThunk(
  '/admin/news/create',
  async (news: ICreateNewsLocal, { rejectWithValue }) => {
    try {
      const data: ICreateNewsState = await apiSdk.newsApis.createNews(news);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteNews = createAsyncThunk(
  '/admin/news/delete',
  async (newsId: INewsDeleteLocal, { rejectWithValue }) => {
    try {
      const data: any = await apiSdk.newsApis.deleteNews(newsId);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
