/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { IHeader, translate } from '../../libs/utils';
import { Box, CircularProgress, Stack } from '@mui/material';
import TableBody from '../../components/questionnaire/TableBody';
import { useAppDispatch } from '../../redux';
import { useSelector } from 'react-redux';
import TableButton from '../../components/table/TableButton';
import Table from '../../components/table/Table';
import { useNavigate } from 'react-router-dom';
import { getQuestionnaires } from '../../redux/questionnaires/action';
import { questionnairesSelector } from '../../redux/questionnaires/selectors';
import { clearState } from '../../redux/questionnaires';

const headers: IHeader[] = [
  { id: 'title', label: translate('questionnaire.table.title') },
  {
    id: 'target_area',
    label: translate('questionnaire.table.target_area'),
  },
  {
    id: 'detail',
    label: translate('questionnaire.table.details'),
  },
  { id: 'open', label: translate('questionnaire.table.open') },
  {
    id: 'edit_action',
    label: translate('questionnaire.table.edit_action'),
  },
];

const QuestionnairesScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const questionnaires = useSelector(questionnairesSelector);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const redirectToQuestionnaireDetail = (id: number) => {
    if (id) {
      dispatch(clearState());
      navigate(`/questionnaires/${id}`);
    }
  };

  const redirectToCreateQuestionnaireScreen = () => {
    dispatch(clearState());
    navigate('/questionnaires/create');
  };

  useEffect(() => {
    dispatch(getQuestionnaires(1));
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [dispatch]);

  return (
    <Layout title={translate('questionnaire.title')}>
      {isLoading && (
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Stack>
      )}

      {!isLoading && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              bgcolor: 'background.paper',
              p: 2,
              borderRadius: 1,
              mb: '5px',
              mt: '-35px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TableButton
                label={translate('member.list.button.add')}
                onClick={redirectToCreateQuestionnaireScreen}
              />
            </Box>
          </Box>
          <Table headers={headers}>
            <TableBody
              data={questionnaires.data}
              onClickDetail={redirectToQuestionnaireDetail}
            />
          </Table>
        </>
      )}
    </Layout>
  );
};
export default QuestionnairesScreen;
