/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import {
  getSeminars,
  getSeminar,
  createSeminar,
  getParticipantsEachSeminar,
  editSeminar,
  exportParticipantsEachSeminarCsv,
} from './action';
import {
  ISeminarsState,
  ISeminarDetailState,
  ISeminarWithParticipantsState,
  ICreateSeminarState,
  IEditSeminarState,
  ICreateSeminarError,
  IEditSeminarError,
} from './types';

interface IState {
  list: ISeminarsState;
  detail: ISeminarDetailState;
  create: ICreateSeminarState;
  edit: IEditSeminarState;
  detailWithParticipants: ISeminarWithParticipantsState;
  exportCsv: any;
  errors?: ICreateSeminarError | IEditSeminarError;
}

const initValue = {
  seminar_id: 0,
  title: '',
  organizer_name: '',
  detail: '',
  seminar_type: 0,
  isOpen: false,
  accept_buddy: 0,
  web_url: '',
  seminar_start_time: new Date(),
  seminar_end_time: new Date(),
  apply_start_at: new Date(),
  apply_end_at: new Date(),
  additional_dates: [],
  target_area: [],
  target_member_type: {
    regular_member: true,
    junior_member: false,
    office_staff: false,
  },
};

const defaultState = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: initValue,
};

export const initialState: IState = {
  list: {
    message: '',
    success: false,
    status: HTTP_STATUS.UNAUTHORIZED,
    data: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0,
      seminars: [],
    },
  },
  detail: {
    message: '',
    success: false,
    status: HTTP_STATUS.UNAUTHORIZED,
    data: {
      ...initValue,
      data_at: new Date(),
      updated_at: new Date(),
    },
  },
  create: defaultState,
  edit: defaultState,
  detailWithParticipants: {
    message: '',
    success: false,
    status: HTTP_STATUS.UNAUTHORIZED,
    data: {
      seminar: {
        seminar_id: 0,
        title: '',
        organizer_name: '',
        detail: '',
        seminar_type: 0,
        open: false,
        accept_buddy: 0,
        web_url: '',
        data_at: new Date(),
        updated_at: new Date(),
        apply_start_at: new Date(),
        apply_end_at: new Date(),
        seminar_start_time: new Date(),
        seminar_end_time: new Date(),
        target_area: [],
        additional_dates: [],
        target_member_type: {
          regular_member: false,
          junior_member: false,
          office_staff: false,
        },
      },
      participants: {
        total: 0,
        per_page: 0,
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
        items: [],
      },
    },
  },
  exportCsv: {
    data: null,
  },
};

const seminarSlice = createGenericSlice({
  name: 'seminar',
  initialState,
  reducers: {
    clearCsv: (state) => {
      state.exportCsv.data = null;
    },
    clearErrors: (state) => {
      state.errors = undefined;
    },
    clearSuccess: (state) => {
      state.create.success = false;
      state.edit.success = false;
    },
    clearStatus: (state) => {
      state.create.status = HTTP_STATUS.UNAUTHORIZED;
      state.edit.status = HTTP_STATUS.UNAUTHORIZED;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSeminars.rejected, (state, action: any) => {
      state.list.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.list.message = action.payload.message;
        state.list.success = action.payload.success;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.list.status = action.payload.status;
      }
    });

    builder.addCase(
      getSeminars.fulfilled,
      (state, action: PayloadAction<ISeminarsState>) => {
        state.list.success = action.payload.success;
        state.list.data = action.payload.data;
        state.list.status = action.payload.status;
      },
    );

    builder.addCase(getSeminar.rejected, (state, action: any) => {
      state.detail.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.detail.message = action.payload.message;
        state.detail.success = action.payload.success;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.detail.status = action.payload.status;
      }
    });

    builder.addCase(
      getSeminar.fulfilled,
      (state, action: PayloadAction<ISeminarDetailState>) => {
        state.detail.success = action.payload.success;
        state.detail.data = action.payload.data;
        state.detail.status = action.payload.status;
      },
    );

    builder.addCase(createSeminar.rejected, (state, action: any) => {
      state.create.status = action.payload.status;
      state.create.success = action.payload.success;

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(createSeminar.fulfilled, (state, action: any) => {
      state.create.success = action.payload.success;
      state.create.status = action.payload.status;
    });

    builder.addCase(editSeminar.rejected, (state, action: any) => {
      state.edit.status = action.payload.status;
      state.edit.success = action.payload.success;

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(editSeminar.fulfilled, (state, action: any) => {
      state.edit.success = action.payload.success;
      state.edit.status = action.payload.status;
    });

    builder.addCase(
      getParticipantsEachSeminar.rejected,
      (state, action: any) => {
        state.detailWithParticipants.status = action.payload.status;
        if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
          state.detailWithParticipants.message = action.payload.message;
          state.detailWithParticipants.success = action.payload.success;
        }
      },
    );

    builder.addCase(getParticipantsEachSeminar.pending, (state, _) => {
      state.detailWithParticipants = initialState.detailWithParticipants;
    });

    builder.addCase(
      getParticipantsEachSeminar.fulfilled,
      (state, action: PayloadAction<ISeminarWithParticipantsState>) => {
        state.detailWithParticipants.success = action.payload.success;
        state.detailWithParticipants.data = action.payload.data;
        state.detailWithParticipants.status = action.payload.status;
      },
    );

    builder.addCase(
      exportParticipantsEachSeminarCsv.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.exportCsv.data = action.payload;
      },
    );
  },
});

export const { clearCsv, clearErrors, clearSuccess, clearStatus } =
  seminarSlice.actions;
export default seminarSlice.reducer;
