import { RootState } from '..';
import {
  IQuestionnaireErrorState,
  IQuestionnairesState,
  IQuestionnaireState,
} from './types';

export const questionnairesSelector = (
  state: RootState,
): IQuestionnairesState => state.questionnaire.list;

export const questionnaireSelector = (state: RootState): IQuestionnaireState =>
  state.questionnaire.detail;

export const errorsSelector = (
  state: RootState,
): IQuestionnaireErrorState | undefined => state.questionnaire.errors;

export const createQuestionnaireSelector = (
  state: RootState,
): IQuestionnaireState => state.questionnaire.create;

export const editQuestionnaireSelector = (
  state: RootState,
): IQuestionnaireState => state.questionnaire.edit;
