import { Stack, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import { FC } from 'react';
import { translate } from '../../libs/utils';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';

interface IApplyDateTimeProps {
  applyStartAt: Dayjs;
  applyEndAt: Dayjs;
  setApplyStartAt: (date: Dayjs) => void;
  setApplyEndAt: (date: Dayjs) => void;
}

const ApplyDateTime: FC<IApplyDateTimeProps> = ({
  applyStartAt,
  applyEndAt,
  setApplyStartAt,
  setApplyEndAt,
}) => {
  return (
    <Stack flexDirection="row" alignItems="left" gap={1}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={translate('seminar.detail.item.start_date')}
          dateValue={applyStartAt}
          onChange={setApplyStartAt}
        />
        <TimePicker
          label={translate('seminar.detail.item.start_time')}
          value={applyStartAt}
          onChange={setApplyStartAt}
        />
        <Typography sx={{ margin: '0 40px', alignContent: 'center' }}>
          to
        </Typography>
        <DatePicker
          label={translate('seminar.detail.item.end_date')}
          dateValue={applyEndAt}
          onChange={setApplyEndAt}
        />
        <TimePicker
          label={translate('seminar.detail.item.end_time')}
          value={applyEndAt}
          onChange={setApplyEndAt}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default ApplyDateTime;
