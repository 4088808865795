/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import {
  getQuestionnaires,
  getQuestionnaire,
  createQuestionnaire,
  editQuestionnaire,
} from './action';
import {
  IQuestionnaireErrorState,
  IQuestionnairesState,
  IQuestionnaireState,
} from './types';

type IInitStateValue = {
  create: IQuestionnaireState;
  edit: IQuestionnaireState;
  detail: IQuestionnaireState;
  list: IQuestionnairesState;
  errors?: IQuestionnaireErrorState;
};

const questionnairesInitialStateValue = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: [],
};

const questionnaireInitialSateValue = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: {
    title: '',
    detail: '',
    url: '',
    isOpen: false,
    all_areas: false,
    target_area: [],
  },
};

export const initialState: IInitStateValue = {
  create: questionnaireInitialSateValue,
  edit: questionnaireInitialSateValue,
  detail: questionnaireInitialSateValue,
  list: questionnairesInitialStateValue,
};

const questionnaireSlice = createGenericSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    clearState: (state) => {
      state.create = questionnaireInitialSateValue;
      state.edit = questionnaireInitialSateValue;
      state.list = questionnairesInitialStateValue;
      state.errors = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuestionnaires.rejected, (state, action: any) => {
      state.list.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.list.message = action.payload.message;
        state.list.success = action.payload.success;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.list.status = action.payload.status;
      }
    });

    builder.addCase(
      getQuestionnaires.fulfilled,
      (state, action: PayloadAction<IQuestionnairesState>) => {
        state.list.success = action.payload.success;
        state.list.data = action.payload.data;
        state.list.status = action.payload.status;
      },
    );

    builder.addCase(
      getQuestionnaire.fulfilled,
      (state, action: PayloadAction<IQuestionnaireState>) => {
        state.detail.success = action.payload.success;
        state.detail.data = action.payload.data;
        state.detail.status = action.payload.status;
      },
    );

    builder.addCase(getQuestionnaire.rejected, (state, action: any) => {
      state.detail.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.detail.message = action.payload.message;
        state.detail.success = action.payload.success;
      }
    });

    builder.addCase(
      createQuestionnaire.fulfilled,
      (state, action: PayloadAction<IQuestionnaireState>) => {
        state.create.success = action.payload.success;
        state.create.data = action.payload.data;
        state.create.status = action.payload.status;
      },
    );

    builder.addCase(createQuestionnaire.rejected, (state, action: any) => {
      state.create.status = action.payload.status;

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.errors = action.payload.errors;
      }

      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.create.message = action.payload.message;
        state.create.success = action.payload.success;
      }
    });

    builder.addCase(
      editQuestionnaire.fulfilled,
      (state, action: PayloadAction<IQuestionnaireState>) => {
        state.edit.success = action.payload.success;
        state.edit.data = action.payload.data;
        state.edit.status = action.payload.status;
      },
    );

    builder.addCase(editQuestionnaire.rejected, (state, action: any) => {
      state.edit.status = action.payload.status;

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.errors = action.payload.errors;
      }

      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.edit.message = action.payload.message;
        state.edit.success = action.payload.success;
      }
    });
  },
});

export const { clearState } = questionnaireSlice.actions;
export default questionnaireSlice.reducer;
