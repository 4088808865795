import { TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  MAIN_COLOR,
  MAIN_LIGHT_COLOR,
  SECOND_LIGHT_GRAY,
} from '../../themes/colors';
import { TABLE_CELL } from '../../themes/fonts';
import { IAnswerSafety } from '../../redux/safety/types';

interface Props {
  data: IAnswerSafety[];
  onClickDetail: (data: IAnswerSafety) => void;
}

const useStyles = makeStyles({
  tableCellText: {
    color: `${SECOND_LIGHT_GRAY} !important`,
    fontSize: `${TABLE_CELL} !important`,
  },

  icon: {
    color: MAIN_COLOR,
    '&:hover': {
      color: MAIN_LIGHT_COLOR,
      cursor: 'pointer',
    },
  },
});

const TableBody = ({ data, onClickDetail }: Props) => {
  const styles = useStyles();

  return (
    <>
      {data?.map((row, rowIndex) => (
        <TableRow key={rowIndex} onClick={() => onClickDetail(row)}>
          <TableCell
            align="center"
            key="user_id"
            className={styles.tableCellText}
          >
            {row['user']['user_id']}
          </TableCell>
          <TableCell
            align="center"
            key="target_area"
            className={styles.tableCellText}
          >
            {row['user']['area']['area_name']}
          </TableCell>
          <TableCell
            align="center"
            key="kanji_name"
            className={styles.tableCellText}
          >
            {row['user']['kanji_name']}
          </TableCell>
          <TableCell
            align="center"
            key="email"
            className={styles.tableCellText}
          >
            {row['user']['email']}
          </TableCell>
          <TableCell
            align="center"
            key="is_damage"
            className={styles.tableCellText}
          >
            {row['is_damage'] ? 'あり' : 'なし'}
          </TableCell>
          <TableCell
            align="center"
            key="created_at"
            className={styles.tableCellText}
          >
            {row['created_at']}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TableBody;
