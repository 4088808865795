import './App.css';
import '@fontsource/manrope';
import { ThemeProvider } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import i18n from './languages/i18n';
import theme from './themes';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/auth/Login';
import ProtectedRoute from './components/ProtectedRoute';
import AllSeminarScreen from './pages/seminars/list';
import MembersScreen from './pages/members/list';
import ParticipantsScreen from './pages/participants/list';
import CreateNotificationScreen from './pages/notifications/create';
import AdminUsersScreen from './pages/admin-users/list';
import CreateMemberScreen from './pages/members/create';
import SeminarDetailScreen from './pages/seminars/detail';
import CreateSeminarScreen from './pages/seminars/create';
import DetailParticipantScreen from './pages/participants/detail';
import AllSafetyScreen from './pages/safety/list';
import DetailSafetyScreen from './pages/safety/detail';
import QuestionnairesScreen from './pages/questionnaire/list';
import CreateQuestionnaireScreen from './pages/questionnaire/create';
import CreateSafetyScreen from './pages/safety/create';
import NewsScreen from './pages/news/list';
import CreateNewsScreen from './pages/news/create';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Navigate to="/members" />} />
              <Route path="/seminars" element={<AllSeminarScreen />} />
              <Route path="/seminars/:id" element={<SeminarDetailScreen />} />
              <Route
                path="/seminars/create"
                element={<CreateSeminarScreen />}
              />
              <Route
                path="/seminars/:id/edit"
                element={<CreateSeminarScreen />}
              />
              <Route path="/members" element={<MembersScreen />} />
              <Route path="/members/create" element={<CreateMemberScreen />} />
              <Route path="/members/:id" element={<CreateMemberScreen />} />
              <Route path="/participants" element={<ParticipantsScreen />} />
              <Route
                path="seminars/:id/participants"
                element={<DetailParticipantScreen />}
              />
              <Route
                path="/notifications"
                element={<CreateNotificationScreen />}
              />
              <Route path="/news" element={<NewsScreen />} />
              <Route path="/news/create" element={<CreateNewsScreen />} />
              <Route path="/safety" element={<AllSafetyScreen />} />
              <Route path="/safety/create" element={<CreateSafetyScreen />} />
              <Route path="/safety/:id" element={<DetailSafetyScreen />} />
              <Route path="/role" element={<AdminUsersScreen />} />
              <Route
                path="/questionnaires"
                element={<QuestionnairesScreen />}
              />
              <Route
                path="/questionnaires/create"
                element={<CreateQuestionnaireScreen />}
              />
              <Route
                path="/questionnaires/:id"
                element={<CreateQuestionnaireScreen />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
