/* eslint-disable no-undef */
import { FC } from 'react';
import { Stack } from '@mui/material';
import { translate } from '../../libs/utils';
import Checkbox from '../Checkbox';
import { ITargetMemberType } from '../../redux/seminars/types';

enum TargetMemberTypeProps {
  REGULAR_MEMBER = 'regular_member',
  JUNIOR_MEMBER = 'junior_member',
  OFFICE_STAFF = 'office_staff',
}

interface Props {
  targetMemberType: ITargetMemberType;
  setTargetMemberType?: (data: ITargetMemberType) => void;
}

const TargetMemberTypeField: FC<Props> = ({
  targetMemberType,
  setTargetMemberType,
}) => {
  const handleSetTargetMemberType = (propName: string) => {
    if (setTargetMemberType) {
      const newTargetMemberType = { ...targetMemberType };
      if (propName === TargetMemberTypeProps.REGULAR_MEMBER) {
        newTargetMemberType.regular_member =
          !newTargetMemberType.regular_member;
      } else if (propName === TargetMemberTypeProps.JUNIOR_MEMBER) {
        newTargetMemberType.junior_member = !newTargetMemberType.junior_member;
      } else if (propName === TargetMemberTypeProps.OFFICE_STAFF) {
        newTargetMemberType.office_staff = !newTargetMemberType.office_staff;
      }
      setTargetMemberType({ ...newTargetMemberType });
    }
  };

  return (
    <Stack flexDirection={'row'} ml={0.5}>
      <Stack mr={2}>
        <Checkbox
          checked={targetMemberType.regular_member}
          label={translate(
            'seminar.detail.item.target_member_type.regular_member',
          )}
          onClick={() =>
            handleSetTargetMemberType(TargetMemberTypeProps.REGULAR_MEMBER)
          }
        />
      </Stack>
      <Stack mr={2}>
        <Checkbox
          checked={targetMemberType.junior_member}
          label={translate(
            'seminar.detail.item.target_member_type.junior_member',
          )}
          onClick={() =>
            handleSetTargetMemberType(TargetMemberTypeProps.JUNIOR_MEMBER)
          }
        />
      </Stack>
      <Stack mr={2}>
        <Checkbox
          checked={targetMemberType.office_staff}
          label={translate(
            'seminar.detail.item.target_member_type.office_staff',
          )}
          onClick={() =>
            handleSetTargetMemberType(TargetMemberTypeProps.OFFICE_STAFF)
          }
        />
      </Stack>
    </Stack>
  );
};

export default TargetMemberTypeField;
