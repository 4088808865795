/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { IHeader, IUserType, translate } from '../../libs/utils';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table as MTable,
  TableBody as MTableBody,
  TableContainer,
  TextField,
  Typography,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  LIGHT_PURPLE,
  MAIN_LIGHT_COLOR,
  TYPOGRAPHY,
  WHITE,
} from '../../themes/colors';
import Chip from '../../components/Chip';
import Button from '../../components/Button';
import { useAppDispatch } from '../../redux';
import { useNavigate, useParams } from 'react-router-dom';
import { exportAnswerCsv, getDetailSafety } from '../../redux/safety/action';
import { IAnswerSafety, ISafety } from '../../redux/safety/types';
import { useSelector } from 'react-redux';
import {
  exportCsvSelector,
  safetySelector,
} from '../../redux/safety/selectors';
import { UserType } from '../../configs';
import { TABLE_HEADER, TOPIC_LABEL } from '../../themes/fonts';
import TableBody from '../../components/detail-safety/TableBody';
import Pagination from '../../components/table/Pagination';
import { calculateTotalPage } from '../../libs/utils/calculateTotalPage';
import ModalSafetyAnswer from '../../components/detail-safety/ModalSafetyAnswer';
import EXPORT_CSV from '../../assets/icons/export-csv.svg';
import { clearCsv } from '../../redux/safety';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const useStyles = makeStyles({
  labelContainer: {
    minWidth: 'calc(241 / 1218 * 100%) !important',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '18px',
    height: '100%',
  },

  textLabel: {
    color: WHITE,
    fontSize: `${TOPIC_LABEL} !important`,
    fontWeight: 'bold !important',
    textAlign: 'center',
  },

  inputField: {
    width: 'calc(718 / 959 * 100%)',
  },

  selectContainer: {
    width: '160px',
    marginLeft: '40px',
  },

  customSelectInput: {
    borderRadius: '12px !important',
    height: '50px !important',
  },

  tableHeader: {
    backgroundColor: MAIN_LIGHT_COLOR,
    height: '60px',
  },
});

const memberTypes: IUserType[] = [
  {
    value: UserType.REGULAR,
    type: translate('member.list.table.membership_type.regular'),
  },
  {
    value: UserType.ASSOCIATE,
    type: translate('member.list.table.membership_type.associate'),
  },
  {
    value: UserType.ADMIN_STAFF,
    type: translate('member.list.table.membership_type.admin_staff'),
  },
  {
    value: UserType.ALL_MEMBER,
    type: translate('member.list.table.membership_type.all_member'),
  },
];

const headers: IHeader[] = [
  { id: 'id', label: translate('safety.detail.table.id') },
  {
    id: 'target_area',
    label: translate('safety.detail.table.area'),
  },
  { id: 'name', label: translate('safety.detail.table.name') },
  { id: 'email', label: translate('safety.detail.table.email') },
  {
    id: 'is_damaged',
    label: translate('safety.detail.table.is_damaged'),
  },
  {
    id: 'date_time_answer',
    label: translate('safety.detail.table.date_time_answer'),
  },
];

const DetailSafetyScreen: React.FC = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const safety: ISafety = useSelector(safetySelector);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [dataModal, setDataModal] = useState<IAnswerSafety>();
  const [sort, setSort] = useState<string>('desc');

  const exportSelector = useSelector(exportCsvSelector);

  useEffect(() => {
    if (id)
      dispatch(
        getDetailSafety({ id: parseInt(id), page: currentPage, sort: sort }),
      );
  }, [dispatch, currentPage, id, sort]);

  const handleRedirectBackButton = () => navigate('/safety');

  const handleOnChangePage = (page: number) => setCurrentPage(page);

  const handleOpenModal = (safety: IAnswerSafety) => {
    setDataModal(safety);
    setOpenModal(true);
  };

  const handleExportCsv = () => {
    if (id)
      dispatch(
        exportAnswerCsv({
          id: parseInt(id),
        }),
      );
  };

  useEffect(() => {
    if (exportSelector) {
      const url = window.URL.createObjectURL(new Blob([exportSelector]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = 'participants.csv';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(clearCsv());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportSelector]);

  return (
    <Layout title={translate('safety.detail.title')} maxWidth={1218}>
      <Stack flexDirection="row" alignItems="center" sx={{ height: '82px' }}>
        <Box className={styles.labelContainer}>
          <Typography className={styles.textLabel}>
            {translate('safety.detail.label.title')}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            className={styles.inputField}
            type="text"
            variant="standard"
            value={safety.title}
          />
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" sx={{ height: '100px' }}>
        <Box className={styles.labelContainer}>
          <Typography className={styles.textLabel}>
            {translate('safety.detail.label.detail')}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, paddingY: '10px' }}>
          <TextField
            className={styles.inputField}
            type="text"
            label="Label"
            variant="outlined"
            multiline
            maxRows={2}
            value={safety.detail}
          />
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" sx={{ height: '82px' }}>
        <Box className={styles.labelContainer}>
          <Typography className={styles.textLabel}>
            {translate('safety.detail.label.membership_type')}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: '24px' }}>
          <FormControl className={styles.selectContainer}>
            <InputLabel id="membership-type-select-label">
              {translate('member.create.label.membership_type.select')}
            </InputLabel>
            <Select
              labelId="membership-type-select-label"
              id="membership-type-select"
              label={translate('member.create.label.membership_type.select')}
              className={styles.customSelectInput}
              value={safety.user_type}
            >
              {memberTypes.map((type: IUserType) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" sx={{ height: '104px' }}>
        <Box className={styles.labelContainer}>
          <Typography className={styles.textLabel}>
            {translate('safety.detail.label.target_area')}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Box
            className={styles.inputField}
            sx={{
              backgroundColor: '#F2F2F2',
              minHeight: '104px',
              borderRadius: '5px',
            }}
          >
            <Box
              display="flex"
              alignContent="flex-start"
              flexWrap="wrap"
              flexDirection="row"
              gap={1}
              sx={{
                padding: '8px 12px',
              }}
            >
              {safety.target_area.map((area, index) => (
                <Chip
                  key={index}
                  label={area.area_name}
                  sx={{
                    backgroundColor: LIGHT_PURPLE,
                    width: '12%',
                  }}
                  textSx={{
                    color: TYPOGRAPHY,
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                  closeIcon={false}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Stack>
      <Box style={{ marginTop: 20 }}>
        <TableContainer>
          <MTable>
            <TableHead className={styles.tableHeader}>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    align="center"
                    key={header.id}
                    sx={{
                      color: WHITE,
                      fontWeight: 'bold',
                      fontSize: TABLE_HEADER,
                      minWidth: '55px',
                    }}
                  >
                    {header.id == 'is_damaged' ? (
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setSort(sort == 'desc' ? 'asc' : 'desc')}
                      >
                        {header.label}
                        {sort == 'desc' && <ArrowDropDownIcon />}
                        {sort == 'asc' && <ArrowDropUpIcon />}
                      </Stack>
                    ) : (
                      header.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <MTableBody>
              <TableBody
                data={safety.safety_answers.items}
                onClickDetail={handleOpenModal}
              />
            </MTableBody>
          </MTable>
        </TableContainer>
        <Stack flexDirection="row" alignItems="center">
          <Box sx={{ flexGrow: 1 }}>
            <Pagination
              total={calculateTotalPage(safety.safety_answers.total)}
              page={currentPage}
              onChangePage={handleOnChangePage}
            />
          </Box>
          <IconButton sx={{ padding: '8px' }} onClick={handleExportCsv}>
            <img src={EXPORT_CSV} alt="EXPORT_CSV" width="44px" />
          </IconButton>
        </Stack>
      </Box>
      <Stack
        flexDirection="row"
        justifyContent="center"
        gap={5}
        sx={{ marginTop: '25px' }}
      >
        <Button
          label={translate('button.back')}
          onClick={handleRedirectBackButton}
          sx={{ width: '100px', height: '38px', borderRadius: 0, boxShadow: 0 }}
          textSx={{ fontSize: '14px', fontWeight: 'bold' }}
        />
      </Stack>
      <ModalSafetyAnswer
        title={translate('safety.detail.title')}
        isOpen={openModal}
        data={dataModal}
        onClose={() => setOpenModal(false)}
      />
    </Layout>
  );
};
export default DetailSafetyScreen;
