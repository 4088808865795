import { AxiosInstance } from 'axios';

import { ICreateMember, IMemberLocal } from './types';
import {
  ICreateMemberState,
  IMembersState,
  IMemberState,
} from '../../../redux/members/types';

export class MemberApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async getMembers(member: IMemberLocal): Promise<IMembersState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/members?page=${member.page}&search=${member.search}`,
    );
    return data;
  }

  async getMember(memberId: string): Promise<IMemberState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/members/${memberId}`,
    );
    return data;
  }

  async createMember(member: ICreateMember): Promise<ICreateMemberState> {
    const { data } = await this.axiosInstance.post('/api/admin/members', {
      kanji_name: member.kanji_name,
      furigana_name: member.furigana_name,
      email: member.email,
      area_id: member.area_id,
      dental_registration_number: member.dental_registration_number,
      birth_date: member.birth_date,
      user_type: member.user_type,
    });
    return data;
  }

  async updateMember(
    member: ICreateMember,
    id: string,
  ): Promise<ICreateMemberState> {
    const { data } = await this.axiosInstance.put(`/api/admin/members/${id}`, {
      kanji_name: member.kanji_name,
      furigana_name: member.furigana_name,
      email: member.email,
      area_id: member.area_id,
      dental_registration_number: member.dental_registration_number,
      birth_date: member.birth_date,
      user_type: member.user_type,
    });
    return data;
  }
}
