import { Box, Modal, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { MAIN_LIGHT_COLOR, WHITE } from '../../themes/colors';
import { translate } from '../../libs/utils';
import Button from '../Button';
import { TOPIC_LABEL } from '../../themes/fonts';
import { IAnswerSafety } from '../../redux/safety/types';

interface Props {
  title: string;
  isOpen: boolean;
  data: IAnswerSafety | undefined;
  onClose: () => void;
}

const useStyles = makeStyles({
  container: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1218px',
    backgroundColor: WHITE,
    borderWidth: '0px',
    borderRadius: '6px',
    boxShadow: '10px',
    padding: '20px',
  },

  titleModal: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '21px',
  },

  labelContainer: {
    width: 'calc(365 / 660 * 100%) !important',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    paddingTop: '4px',
  },

  questionContainer: {
    alignItems: 'center',
    height: '60px',
  },

  textLabel: {
    color: WHITE,
    fontSize: `${TOPIC_LABEL} !important`,
    fontWeight: 'bold !important',
    textAlign: 'left',
    paddingLeft: '30px',
    paddingRight: '30px',
  },

  textAnswer: {
    flex: 1,
    textAlign: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const ModalSafetyAnswer: FC<Props> = ({
  title,
  isOpen,
  data,
  onClose,
}: Props) => {
  const styles = useStyles();
  const handleClose = () => onClose();

  const isShowAllAnswer = data?.answer.question_1.answer === 'あり';

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box className={styles.container}>
        <Stack direction={'column'} gap={2}>
          <Box className={styles.titleModal}>{title}</Box>
          <Stack direction={'row'} spacing={1}>
            <Stack direction={'column'} sx={{ width: '50%' }}>
              <Stack className={styles.questionContainer} flexDirection="row">
                <Box className={styles.labelContainer}>
                  <Typography className={styles.textLabel}>
                    {translate('safety.detail.modal.question_1')}
                  </Typography>
                </Box>
                <Box className={styles.textAnswer}>
                  <Typography>{data?.answer.question_1.answer}</Typography>
                </Box>
              </Stack>
              {isShowAllAnswer && (
                <>
                  <Stack
                    className={styles.questionContainer}
                    flexDirection="row"
                  >
                    <Box className={styles.labelContainer}>
                      <Typography className={styles.textLabel}>
                        {translate('safety.detail.modal.question_2')}
                      </Typography>
                    </Box>
                    <Box className={styles.textAnswer}>
                      <Typography>{data?.answer.question_2.answer}</Typography>
                    </Box>
                  </Stack>
                  <Stack
                    className={styles.questionContainer}
                    flexDirection="row"
                  >
                    <Box className={styles.labelContainer}>
                      <Typography className={styles.textLabel}>
                        {translate('safety.detail.modal.question_3')}
                      </Typography>
                    </Box>
                    <Box className={styles.textAnswer}>
                      <Typography>{data?.answer.question_3.answer}</Typography>
                    </Box>
                  </Stack>
                  <Stack
                    className={styles.questionContainer}
                    flexDirection="row"
                  >
                    <Box className={styles.labelContainer}>
                      <Typography className={styles.textLabel}>
                        {translate('safety.detail.modal.question_4')}
                      </Typography>
                    </Box>
                    <Box className={styles.textAnswer}>
                      <Typography>{data?.answer.question_4.answer}</Typography>
                    </Box>
                  </Stack>
                  <Stack
                    className={styles.questionContainer}
                    flexDirection="row"
                  >
                    <Box className={styles.labelContainer}>
                      <Typography className={styles.textLabel}>
                        {translate('safety.detail.modal.question_5')}
                      </Typography>
                    </Box>
                    <Box className={styles.textAnswer}>
                      <Typography>{data?.answer.question_5.answer}</Typography>
                    </Box>
                  </Stack>
                  {data?.answer.question_5_2.answer_1 && (
                    <Stack
                      className={styles.questionContainer}
                      flexDirection="row"
                    >
                      <Box className={styles.labelContainer}>
                        <Typography className={styles.textLabel}>
                          {translate('safety.detail.modal.question_5_2')}
                        </Typography>
                      </Box>
                      <Box className={styles.textAnswer}>
                        <Typography>
                          {data?.answer.question_5_2.answer_1}
                          {data?.answer.question_5_2.answer_2 && ', '}
                          {data?.answer.question_5_2.answer_2}
                          {data?.answer.question_5_2.answer_3 && ', '}
                          {data?.answer.question_5_2.answer_3}
                          {data?.answer.question_5_2.answer_4 && ', '}
                          {data?.answer.question_5_2.answer_4}
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                </>
              )}
            </Stack>
            <Stack direction={'column'} sx={{ width: '50%' }}>
              {isShowAllAnswer && (
                <>
                  <Stack
                    className={styles.questionContainer}
                    flexDirection="row"
                  >
                    <Box className={styles.labelContainer}>
                      <Typography className={styles.textLabel}>
                        {translate('safety.detail.modal.question_6')}
                      </Typography>
                    </Box>
                    <Box className={styles.textAnswer}>
                      <Typography>{data?.answer.question_6.answer}</Typography>
                    </Box>
                  </Stack>
                  <Stack
                    className={styles.questionContainer}
                    flexDirection="row"
                  >
                    <Box className={styles.labelContainer}>
                      <Typography className={styles.textLabel}>
                        {translate('safety.detail.modal.question_7')}
                      </Typography>
                    </Box>
                    <Box className={styles.textAnswer}>
                      <Typography>{data?.answer.question_7.answer}</Typography>
                    </Box>
                  </Stack>
                  {data?.answer.question_7_2.answer_1 && (
                    <Stack
                      className={styles.questionContainer}
                      flexDirection="row"
                    >
                      <Box className={styles.labelContainer}>
                        <Typography className={styles.textLabel}>
                          {translate('safety.detail.modal.question_7_2')}
                        </Typography>
                      </Box>
                      <Box className={styles.textAnswer}>
                        <Typography>
                          {data?.answer.question_7_2.answer_1}
                          {data?.answer.question_7_2.answer_2 && ', '}
                          {data?.answer.question_7_2.answer_2}
                          {data?.answer.question_7_2.answer_3 && ', '}
                          {data?.answer.question_7_2.answer_3}
                          {data?.answer.question_7_2.answer_4 && ', '}
                          {data?.answer.question_7_2.answer_4}
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                  <Stack
                    className={styles.questionContainer}
                    flexDirection="row"
                  >
                    <Box className={styles.labelContainer}>
                      <Typography className={styles.textLabel}>
                        {translate('safety.detail.modal.question_8')}
                      </Typography>
                    </Box>
                    <Box className={styles.textAnswer}>
                      <Typography>{data?.answer.question_8.answer}</Typography>
                    </Box>
                  </Stack>
                  <Stack
                    className={styles.questionContainer}
                    flexDirection="row"
                  >
                    <Box className={styles.labelContainer}>
                      <Typography className={styles.textLabel}>
                        {translate('safety.detail.modal.contact_phone_number')}
                      </Typography>
                    </Box>
                    <Box className={styles.textAnswer}>
                      <Typography>
                        {data?.answer.contact_phone_number.answer}
                      </Typography>
                    </Box>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="flex-end"
            gap={5}
            sx={{ marginTop: '25px' }}
          >
            <Button
              label={translate('button.back')}
              onClick={handleClose}
              sx={{
                width: '100px',
                height: '38px',
                borderRadius: 0,
                boxShadow: 0,
              }}
              textSx={{ fontSize: '14px', fontWeight: 'bold' }}
            />
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
export default ModalSafetyAnswer;
