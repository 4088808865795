/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICreateNotificationLocal } from '../../libs/apis/notifications/types';
import { apiSdk } from '../../libs/apis';
import { ICreateNotificationsState } from './types';

export const createNotification = createAsyncThunk(
  '/admin/notifications/create',
  async (notification: ICreateNotificationLocal, { rejectWithValue }) => {
    try {
      const data: ICreateNotificationsState =
        await apiSdk.notificationApis.createNotification(notification);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
