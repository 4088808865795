import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Stack,
  FormControlLabel,
  Switch,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Layout from '../../components/Layout';
import { useAppDispatch } from '../../redux';
import {
  createSeminar,
  editSeminar,
  getSeminar,
} from '../../redux/seminars/action';
import { translate } from '../../libs/utils';
import {
  LIGHT_GRAY,
  MAIN_LIGHT_COLOR,
  TITLE,
  WHITE,
} from '../../themes/colors';
import TableButton from '../../components/table/TableButton';
import dayjs, { Dayjs } from 'dayjs';
import FormTextField from '../../components/FormTextField';
import { getAreas } from '../../redux/areas/action';
import { AdditionalDay, HTTP_STATUS, SeminarType } from '../../configs';
import LabelField from '../../components/seminars/LabelField';
import FormProvider from '../../components/FormProvider';
import { useForm } from 'react-hook-form';
import EventTypeField from '../../components/seminars/EventTypeField';
import TargetAreasField from '../../components/seminars/TargetAreasField';
import ApplyDateTime from '../../components/seminars/ApplyDateTime';
import OpeningDayFields from '../../components/seminars/OpeningDayFields';
import {
  IArea,
  ICreateSeminarError,
  ITargetMemberType,
} from '../../redux/seminars/types';
import ErrorMessage from '../../components/ErrorMessage';
import Button from '../../components/Button';
import { useSelector } from 'react-redux';
import {
  errorsSelector,
  statusSelector,
  successSelector,
  seminarSelector,
  editStatusSelector,
  editSuccessSelector,
} from '../../redux/seminars/selectors';
import { IAdditionalDate } from '../../libs/apis/seminars/types';
import AdditionalDayFields from '../../components/seminars/AdditionalDayFields';
import CreatePopup from '../../components/popup/CreatePopup';
import EditPopup from '../../components/popup/EditPopup';
import { clearErrors, clearSuccess, clearStatus } from '../../redux/seminars';
import TargetMemberTypeField from '../../components/seminars/TargetMemberTypeField';
import { areasSelector } from '../../redux/areas/selectors';
import AcceptBuddyField from '../../components/seminars/AcceptBuddyField';

const useStyles = makeStyles({
  container: {
    maxWidth: '1226px',
    margin: '0 32px',
  },
  labelContainer: {
    minWidth: '240px',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  textLabel: {
    color: WHITE,
    fontSize: '14px !important',
    fontWeight: 'bold !important',
  },
  lectureText: {
    '& .MuiInputBase-input': {
      fontSize: '15px',
    },
  },
  height70: { height: '70px' },
  height83: { height: '83px' },
  height140: { height: '140px' },
  height190: { height: '190px' },
});

const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
const timeFormat = 'HH:mm';

const CreateScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const { id } = useParams();
  const methods = useForm();
  const { handleSubmit } = methods;

  const errors = useSelector(errorsSelector);
  const createStatus = useSelector(statusSelector);
  const createSuccess = useSelector(successSelector);
  const editStatus = useSelector(editStatusSelector);
  const editSuccess = useSelector(editSuccessSelector);
  const seminar = useSelector(seminarSelector);
  const allAreas: IArea[] = useSelector(areasSelector);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('');
  const [detail, setDetail] = useState<string>('');
  const [organizer, setOrganizer] = useState<string>('');
  const [isOpen, setIsOpen] = useState<number>(0);
  const [acceptBuddy, setAcceptBuddy] = useState<number>(1);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [isShowEditPopup, setIsShowEditPopup] = useState<boolean>(false);
  const [targetMemberType, setTargetMemberType] = useState<ITargetMemberType>({
    regular_member: false,
    junior_member: false,
    office_staff: false,
  });
  const [areas, setAreas] = useState<IArea[]>([]);
  const [isAllAreas, setIsAllAreas] = useState<boolean>(false);
  const [areasCached, setAreasCached] = useState<IArea[]>([]);
  const [errorsMessage, setErrorsMessage] = useState<
    ICreateSeminarError | undefined
  >();
  const [seminarType, setSeminarType] = useState<number>(
    SeminarType.LOCAL_EVENT,
  );
  const [seminarStartTime, setSeminarStartTime] = useState<Dayjs>(
    dayjs(new Date()),
  );
  const [seminarEndTime, setSeminarEndTime] = useState<Dayjs>(
    dayjs(new Date()),
  );
  const [applyStartAt, setApplyStartAt] = useState<Dayjs>(dayjs(new Date()));
  const [applyEndAt, setApplyEndAt] = useState<Dayjs>(dayjs(new Date()));
  const [additionalDates, setAdditionalDates] = useState<IAdditionalDate[]>([]);

  const handleClearSeminarState = () => {
    dispatch(clearErrors());
    dispatch(clearSuccess());
    dispatch(clearStatus());
    navigate('/seminars');
  };

  const handleBackButton = () => {
    handleClearSeminarState();
  };

  const handleCloseCreatePopup = () => {
    setIsShowPopup(false);
    handleClearSeminarState();
  };

  const handleCloseEditPopup = () => {
    setIsShowEditPopup(false);
    handleClearSeminarState();
  };

  const handleAddAdditionalDate = () => {
    const newAdditionalDates = {
      date: dayjs(new Date()),
      start_time: dayjs(new Date()),
      end_time: dayjs(new Date()),
    };
    setAdditionalDates([...additionalDates, newAdditionalDates]);
  };

  const handleChangeSeminarDate = (date: Dayjs) => {
    const newSeminarDate = date.format(dateFormat);
    const currentSeminarEndTime = seminarEndTime.format(timeFormat);
    setSeminarStartTime(date);
    setSeminarEndTime(
      dayjs(new Date(`${newSeminarDate}T${currentSeminarEndTime}`)),
    );
  };

  const handleChangeSeminarEndTime = (date: Dayjs) => {
    const newSeminarEndTime = date.format(timeFormat);
    const currentSeminarDate = seminarStartTime.format(dateFormat);
    setSeminarEndTime(
      dayjs(new Date(`${currentSeminarDate}T${newSeminarEndTime}`)),
    );
  };

  const handleSetAdditionalDate = (
    index: number,
    value: Dayjs,
    valueType: AdditionalDay,
  ) => {
    const newAdditionalDates = additionalDates?.map(
      (additionalDates: IAdditionalDate, idx: number) => {
        if (idx !== index) return additionalDates;
        if (valueType === AdditionalDay.DATE) additionalDates.date = value;
        if (valueType === AdditionalDay.START_TIME)
          additionalDates.start_time = value;
        if (valueType === AdditionalDay.END_TIME)
          additionalDates.end_time = value;
        return additionalDates;
      },
    );
    setAdditionalDates([...newAdditionalDates]);
  };

  const handleRemoveAdditionalDate = (additionalDateIndex: number) => {
    const newAdditionalDates = additionalDates.filter(
      (additionalDate: IAdditionalDate, index: number) =>
        index !== additionalDateIndex,
    );
    setAdditionalDates([...newAdditionalDates]);
  };

  const onSubmit = handleSubmit(async () => {
    setErrorsMessage(undefined);
    dispatch(clearErrors());
    dispatch(clearSuccess());
    dispatch(clearStatus());
    const seminarData = {
      title: title,
      organizer_name: organizer,
      detail: detail,
      seminar_type: seminarType,
      isOpen: isOpen,
      accept_buddy: acceptBuddy,
      web_url: null,
      seminar_start_time: seminarStartTime.format(dateTimeFormat),
      seminar_end_time: seminarEndTime.format(dateTimeFormat),
      apply_start_at: applyStartAt.format(dateTimeFormat),
      apply_end_at: applyEndAt.format(dateTimeFormat),
      registration_open_time: applyStartAt.format(dateTimeFormat),
      registration_close_time: applyEndAt.format(dateTimeFormat),
      target_member_type: targetMemberType,
      target_area: areas.map((area) => area.area_id),
      additional_dates: additionalDates?.map((additionalDate) => {
        return {
          date: additionalDate.date.format(dateFormat),
          start_time: additionalDate.start_time.format(timeFormat),
          end_time: additionalDate.end_time.format(timeFormat),
        };
      }),
    };

    if (id) dispatch(editSeminar({ seminar_id: parseInt(id), ...seminarData }));
    else dispatch(createSeminar(seminarData));
  });

  useEffect(() => {
    dispatch(getAreas());
    if (id) dispatch(getSeminar({ seminarId: parseInt(id) }));
  }, [dispatch, id]);

  useEffect(() => {
    if (seminar && id) {
      setTitle(seminar.title);
      setDetail(seminar.detail);
      setOrganizer(seminar.organizer_name);
      setIsOpen(seminar.open ? 1 : 0);
      setAcceptBuddy(seminar.accept_buddy);
      setSeminarStartTime(dayjs(seminar.seminar_start_time));
      setSeminarEndTime(dayjs(seminar.seminar_end_time));
      setApplyStartAt(dayjs(seminar.apply_start_at));
      setApplyEndAt(dayjs(seminar.apply_end_at));
      setAreas(seminar.target_area);
      setTargetMemberType(seminar.target_member_type);
      setSeminarType(seminar.seminar_type);
      setAdditionalDates(
        seminar.additional_dates?.map((additionalDate) => {
          return {
            date: dayjs(`${additionalDate.date}T${additionalDate.start_time}`),
            start_time: dayjs(
              `${additionalDate.date}T${additionalDate.start_time}`,
            ),
            end_time: dayjs(
              `${additionalDate.date}T${additionalDate.end_time}`,
            ),
          };
        }),
      );

      if (seminar.target_area.length !== areasCached.length) {
        setAreas(seminar.target_area as IArea[]);
        setAreasCached(seminar.target_area as IArea[]);
      }

      if (areas.length === allAreas.length) {
        setIsAllAreas(true);
      } else {
        setIsAllAreas(false);
      }
    }
    // eslint-disable-next-line no-undef
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, seminar]);

  useEffect(() => {
    if (errors) {
      setErrorsMessage(errors);
      return;
    }

    if (createStatus === HTTP_STATUS.OK && createSuccess) {
      setErrorsMessage(undefined);
      setIsShowPopup(true);
      return;
    }

    if (editStatus === HTTP_STATUS.OK && editSuccess) {
      setErrorsMessage(undefined);
      setIsShowEditPopup(true);
      return;
    }
  }, [createStatus, createSuccess, editStatus, editSuccess, errors]);

  return (
    <Layout
      title={translate(id ? 'seminar.edit.title' : 'seminar.create.title')}
    >
      {isLoading && (
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Stack>
      )}
      {!isLoading && (
        <FormProvider methods={{ ...methods }} onSubmit={onSubmit}>
          <LabelField
            label={translate('seminar.detail.title')}
            content={
              <FormTextField
                name="title"
                value={title}
                setText={setTitle}
                multiline={false}
                rows={1}
                errorMessage={errorsMessage?.title}
              />
            }
            height={styles.height83}
          />
          <LabelField
            label={translate('seminar.detail.lecture_details')}
            content={
              <FormTextField
                name="detail"
                value={detail}
                setText={setDetail}
                multiline={true}
                rows={4}
                label={'Label'}
                errorMessage={errorsMessage?.detail}
              />
            }
            height={styles.height140}
          />
          <LabelField
            label={translate('seminar.detail.organizer')}
            content={
              <FormTextField
                name={'organizer_name'}
                value={organizer}
                setText={setOrganizer}
                multiline={false}
                rows={1}
                errorMessage={errorsMessage?.organizer_name}
              />
            }
            height={styles.height83}
          />
          <LabelField
            label={translate('seminar.detail.item.target_member_type')}
            content={
              <TargetMemberTypeField
                targetMemberType={targetMemberType}
                setTargetMemberType={setTargetMemberType}
              />
            }
            height={styles.height83}
          />
          <LabelField
            label={translate('seminar.detail.target_audience')}
            content={
              <Stack flexDirection={'column'}>
                <TargetAreasField
                  allAreas={allAreas}
                  areas={areas}
                  isSelectAllAreas={isAllAreas}
                  setIsSelectAllAreas={setIsAllAreas}
                  setAreas={setAreas}
                />
                {errorsMessage?.target_area && (
                  <Box sx={{ marginTop: '14px' }}>
                    <ErrorMessage
                      message={
                        errorsMessage?.target_area.length > 0
                          ? errorsMessage?.target_area[0]
                          : ''
                      }
                    />
                  </Box>
                )}
              </Stack>
            }
            height={styles.height190}
          />
          <LabelField
            label={translate('seminar.detail.opening_day')}
            content={
              <Stack
                overflow={'auto'}
                paddingTop={'8px'}
                height={additionalDates?.length >= 1 ? '190px' : undefined}
              >
                <OpeningDayFields
                  eventStartAt={seminarStartTime}
                  setEventStartAt={handleChangeSeminarDate}
                  eventEndAt={seminarEndTime}
                  setEventEndAt={handleChangeSeminarEndTime}
                  errorMessage={
                    errorsMessage?.seminar_start_time ||
                    errorsMessage?.seminar_end_time
                  }
                />
                {additionalDates?.map((additionalDate, index) => (
                  <React.Fragment key={index}>
                    <span
                      key={`${index}abc`}
                      style={{ marginTop: '15px' }}
                    ></span>
                    <AdditionalDayFields
                      key={`${index}df12`}
                      index={index}
                      additionalDate={additionalDate}
                      setAdditionalDate={handleSetAdditionalDate}
                      onRemove={handleRemoveAdditionalDate}
                    />
                  </React.Fragment>
                ))}
                {errorsMessage?.additional_dates && (
                  <Box sx={{ marginTop: '14px' }}>
                    <ErrorMessage
                      message={
                        errorsMessage?.additional_dates.length > 0
                          ? errorsMessage?.additional_dates[0]
                          : ''
                      }
                    />
                  </Box>
                )}
              </Stack>
            }
            height={
              additionalDates?.length >= 1 ? styles.height190 : styles.height83
            }
          />
          <LabelField
            label={translate('seminar.create.add_event')}
            content={
              <Stack
                alignItems={'center'}
                justifyItems={'center'}
                marginLeft={'180px'}
              >
                <Button
                  label="+"
                  sx={{
                    width: '173px',
                    height: '38px',
                    boxShadow: 0,
                    backgroundColor: LIGHT_GRAY,
                    color: TITLE,
                  }}
                  textSx={{ fontSize: '14px', fontWeight: 'bold' }}
                  onClick={handleAddAdditionalDate}
                />
              </Stack>
            }
            height={styles.height70}
          />
          <LabelField
            label={translate('seminar.detail.apply_time')}
            content={
              <Stack>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <ApplyDateTime
                    applyStartAt={applyStartAt}
                    applyEndAt={applyEndAt}
                    setApplyStartAt={setApplyStartAt}
                    setApplyEndAt={setApplyEndAt}
                  />
                </Stack>
                <Stack flexDirection={'row'}>
                  {errorsMessage?.apply_start_at && (
                    <Box sx={{ marginTop: '14px' }}>
                      <ErrorMessage
                        message={
                          errorsMessage?.apply_start_at.length > 0
                            ? errorsMessage?.apply_start_at[0]
                            : ''
                        }
                      />
                    </Box>
                  )}
                  {errorsMessage?.apply_end_at && (
                    <Box sx={{ marginTop: '14px' }}>
                      <ErrorMessage
                        message={
                          errorsMessage?.apply_end_at.length > 0
                            ? errorsMessage?.apply_end_at[0]
                            : ''
                        }
                      />
                    </Box>
                  )}
                </Stack>
              </Stack>
            }
            height={styles.height83}
          />
          <LabelField
            label={translate('seminar.detail.publicity')}
            content={
              <Stack>
                <Box sx={{ flexGrow: 1 }}>
                  <FormControlLabel
                    control={<Switch checked={isOpen === 1 ? true : false} />}
                    label={translate('seminar.detail.item.public')}
                    onClick={() => setIsOpen(isOpen === 1 ? 0 : 1)}
                  />
                </Box>
                {errorsMessage?.isOpen && (
                  <Box sx={{ marginTop: '14px' }}>
                    <ErrorMessage
                      message={
                        errorsMessage?.isOpen.length > 0
                          ? errorsMessage?.isOpen[0]
                          : ''
                      }
                    />
                  </Box>
                )}
              </Stack>
            }
            height={styles.height70}
          />
          <LabelField
            label={translate('seminar.detail.event_type')}
            content={
              <Stack flexDirection="row" gap={4}>
                <Stack>
                  <EventTypeField
                    seminarType={seminarType}
                    setSeminarType={setSeminarType}
                  />
                  {errorsMessage?.seminar_type && (
                    <Box sx={{ marginTop: '14px' }}>
                      <ErrorMessage
                        message={
                          errorsMessage?.seminar_type.length > 0
                            ? errorsMessage?.seminar_type[0]
                            : ''
                        }
                      />
                    </Box>
                  )}
                </Stack>
                <Stack>
                  <AcceptBuddyField
                    acceptBuddy={acceptBuddy}
                    setAcceptBuddy={setAcceptBuddy}
                  />
                </Stack>
              </Stack>
            }
            height={styles.height83}
          />
          <Stack
            flexDirection="row"
            justifyContent="center"
            gap={5}
            sx={{ marginY: '40px' }}
          >
            <Button
              type="submit"
              label={
                id
                  ? translate('button.edit')
                  : translate('seminar.create.button_create')
              }
              sx={{
                width: '40px',
                height: '38px',
                borderRadius: 0,
                boxShadow: 0,
              }}
              textSx={{ fontSize: '14px', fontWeight: 'bold' }}
            />
            <TableButton
              label={translate('seminar.create.button_cancel')}
              onClick={handleBackButton}
            />
          </Stack>
        </FormProvider>
      )}
      {isShowPopup && (
        <CreatePopup
          onConfirm={handleCloseCreatePopup}
          onClose={handleCloseCreatePopup}
          open={isShowPopup}
        />
      )}
      {isShowEditPopup && (
        <EditPopup
          onConfirm={handleCloseEditPopup}
          onClose={handleCloseEditPopup}
          open={isShowEditPopup}
        />
      )}
    </Layout>
  );
};

export default CreateScreen;
