/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from 'axios';
import {
  ISeminarLocal,
  ISeminarDetailLocal,
  ICreateSeminar,
  ISeminarWithParticipantsLocal,
  IEditSeminar,
} from './types';
import {
  ICreateSeminarState,
  IEditSeminarState,
  ISeminarDetailState,
  ISeminarsState,
  ISeminarWithParticipantsState,
} from '../../../redux/seminars/types';

export class SeminarApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async getSeminars(seminar: ISeminarLocal): Promise<ISeminarsState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/seminars?page=${seminar.page}`,
    );
    return data;
  }

  async getSeminar(seminar: ISeminarDetailLocal): Promise<ISeminarDetailState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/seminars/${seminar.seminarId}`,
    );
    return data;
  }

  async createSeminar(seminar: ICreateSeminar): Promise<ICreateSeminarState> {
    const { data } = await this.axiosInstance.post(`/api/admin/seminars`, {
      title: seminar.title,
      organizer_name: seminar.organizer_name,
      detail: seminar.detail,
      seminar_type: seminar.seminar_type,
      isOpen: seminar.isOpen,
      accept_buddy: seminar.accept_buddy,
      web_url: seminar.web_url,
      seminar_start_time: seminar.seminar_start_time,
      seminar_end_time: seminar.seminar_end_time,
      apply_start_at: seminar.apply_start_at,
      apply_end_at: seminar.apply_end_at,
      registration_open_time: seminar.registration_open_time,
      registration_close_time: seminar.registration_close_time,
      additional_dates: seminar.additional_dates,
      target_area: seminar.target_area,
      target_member_type: seminar.target_member_type,
    });
    return data;
  }

  async editSeminar(seminar: IEditSeminar): Promise<IEditSeminarState> {
    const { data } = await this.axiosInstance.put(
      `/api/admin/seminars/${seminar.seminar_id}`,
      {
        title: seminar.title,
        organizer_name: seminar.organizer_name,
        detail: seminar.detail,
        seminar_type: seminar.seminar_type,
        isOpen: seminar.isOpen,
        accept_buddy: seminar.accept_buddy,
        web_url: seminar.web_url,
        seminar_start_time: seminar.seminar_start_time,
        seminar_end_time: seminar.seminar_end_time,
        apply_start_at: seminar.apply_start_at,
        apply_end_at: seminar.apply_end_at,
        registration_open_time: seminar.registration_open_time,
        registration_close_time: seminar.registration_close_time,
        additional_dates: seminar.additional_dates,
        target_member_type: seminar.target_member_type,
        target_area: seminar.target_area,
      },
    );
    return data;
  }

  async getParticipantsEachSeminar(
    seminar: ISeminarWithParticipantsLocal,
  ): Promise<ISeminarWithParticipantsState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/seminars/${seminar.seminarId}/participants?page=${seminar.page}`,
    );
    return data;
  }

  async exportParticipantsEachSeminarCsv(
    seminar: ISeminarDetailLocal,
  ): Promise<any> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/seminars/${seminar.seminarId}/participants.csv`,
      {
        headers: {
          'Content-Type': 'text/csv',
        },
        responseType: 'blob',
      },
    );
    return data;
  }
}
