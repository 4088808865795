/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Typography,
  InputAdornment,
  TextField,
} from '@mui/material';
import Button from '../../components/Button';
import ErrorMessage from '../../components/ErrorMessage';
import { emailRegex, passwordRegex, translate } from '../../libs/utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useAppDispatch } from '../../redux';
import { login } from '../../redux/auth/action';
import { useSelector } from 'react-redux';
import {
  emailErrorSelector,
  passwordErrorSelector,
  messageSelector,
  successSelector,
} from '../../redux/auth/selectors';
import { makeStyles } from '@mui/styles';
import { getItemDataStorage } from '../../libs/utils/localStorage';
import { TOKEN_KEYS } from '../../configs';
import { useNavigate } from 'react-router-dom';

const endAdornmentStyle = { width: '20px', height: '20px' };

const useStyles = makeStyles({
  customTextField: {
    '& input::placeholder': {
      lineHeight: '18px',
      fontSize: '12px',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    marginX: 'auto',
  },
});

const Login = () => {
  const styles = useStyles();
  const [isVisibility, setIsVisibility] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailErrorContent, setEmailErrorContent] = useState<string>('');
  const [passwordErrorContent, setPasswordErrorContent] = useState<string>('');
  const [errorContent, setErrorContent] = useState<string>('');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const emailErrorMessage: string[] | undefined =
    useSelector(emailErrorSelector);
  const passwordErrorMessage: string[] | undefined = useSelector(
    passwordErrorSelector,
  );
  const errorMessage: string = useSelector(messageSelector);
  const isSuccess: boolean = useSelector(successSelector);

  useEffect(() => {
    const token = getItemDataStorage(TOKEN_KEYS.ACCESS_TOKEN);
    if (token) navigate('/members');
  }, [navigate]);

  useEffect(() => {
    if (isSuccess) navigate('/members');
    if (errorMessage) {
      setErrorContent(errorMessage);
    } else if (emailErrorMessage) {
      setEmailErrorContent(emailErrorMessage[0]);
    } else if (passwordErrorMessage) {
      setPasswordErrorContent(passwordErrorMessage[0]);
    }
  }, [
    emailErrorMessage,
    errorMessage,
    isSuccess,
    navigate,
    passwordErrorMessage,
  ]);

  const handleOnChangeEmail = (email: string) => {
    if (!emailRegex.test(email)) {
      setEmailErrorContent(translate('login.error.email_invalid_format'));
      return;
    }
    setErrorContent('');
    setEmailErrorContent('');
    setEmail(email);
  };

  const handleOnChangePassword = (password: string) => {
    if (!passwordRegex.test(password)) {
      setPasswordErrorContent(translate('login.error.password_invalid_format'));
      return;
    }
    setErrorContent('');
    setPasswordErrorContent('');
    setPassword(password);
  };

  const handleSubmitButton = () => {
    setEmailErrorContent('');
    setPasswordErrorContent('');
    if (!emailRegex.test(email) && !passwordRegex.test(password)) {
      setErrorContent(translate('login.error.password_email_invalid'));
      return;
    } else if (!emailRegex.test(email) && passwordRegex.test(password)) {
      setErrorContent(translate('login.error.password_email_invalid'));
      return;
    } else if (emailRegex.test(email) && !passwordRegex.test(password)) {
      setErrorContent(translate('login.error.password_email_invalid'));
      return;
    }

    dispatch(login({ email, password }));
  };

  const renderPasswordEndAdornment = () => {
    return (
      <InputAdornment
        position="start"
        sx={{ cursor: 'pointer' }}
        onClick={() => setIsVisibility(!isVisibility)}
      >
        {isVisibility ? (
          <VisibilityIcon sx={endAdornmentStyle} />
        ) : (
          <VisibilityOffIcon sx={endAdornmentStyle} />
        )}
      </InputAdornment>
    );
  };

  return (
    <Box className={styles.container}>
      <Stack direction={'column'} gap={2} width={552}>
        <Box textAlign={'left'}>
          <Typography sx={{ fontSize: '34px' }}>
            {translate('login.title')}
          </Typography>
        </Box>
        <TextField
          name="email"
          type="text"
          className={styles.customTextField}
          error={!!emailErrorContent}
          placeholder={translate('login.fields.email')}
          variant="outlined"
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            handleOnChangeEmail(e.target.value);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleOnChangeEmail(e.target.value);
          }}
        />
        <TextField
          name="password"
          type={isVisibility ? 'text' : 'password'}
          className={styles.customTextField}
          error={!!passwordErrorContent}
          placeholder={translate('login.fields.password')}
          variant="outlined"
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            handleOnChangePassword(e.target.value);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleOnChangePassword(e.target.value);
          }}
          slotProps={{
            input: {
              endAdornment: renderPasswordEndAdornment(),
            },
          }}
        />
        {emailErrorContent && <ErrorMessage message={emailErrorContent} />}
        {passwordErrorContent && (
          <ErrorMessage message={passwordErrorContent} />
        )}
        {!emailErrorContent && !passwordErrorContent && errorContent && (
          <ErrorMessage message={errorContent} />
        )}
        <Button
          label={translate('login.button')}
          onClick={() => handleSubmitButton()}
          sx={{ height: '42px' }}
          textSx={{ fontSize: '15px' }}
        />
      </Stack>
    </Box>
  );
};

export default Login;
