/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import { createNotification } from './action';
import { ICreateNotificationError, ICreateNotificationsState } from './types';

interface IState {
  create: ICreateNotificationsState;
  errors?: ICreateNotificationError;
}

const initValue = {
  title: '',
  detail: '',
  target_area: [],
  created_by: 0,
  notification_id: 0,
};

const defaultState = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: initValue,
};

export const initialState: IState = {
  create: defaultState,
};

const notificationSlice = createGenericSlice({
  name: 'notification',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = undefined;
    },

    clearCreateState: (state) => {
      state.create.message = '';
      state.create.success = false;
      state.create.data = initValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNotification.rejected, (state, action: any) => {
      state.create.status = action.payload.status;
      state.create.success = action.payload.success;

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(
      createNotification.fulfilled,
      (state, action: PayloadAction<ICreateNotificationsState>) => {
        state.create.success = action.payload.success;
        state.create.data = action.payload.data;
        state.create.status = action.payload.status;
      },
    );
  },
});

export const { clearErrors, clearCreateState } = notificationSlice.actions;

export default notificationSlice.reducer;
