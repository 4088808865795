import { FormControl, TableCell, TableRow } from '@mui/material';
import {
  GRAY,
  MAIN_COLOR,
  MAIN_LIGHT_COLOR,
  SECOND_LIGHT_GRAY,
} from '../../themes/colors';
import { TABLE_CELL } from '../../themes/fonts';
import { makeStyles } from '@mui/styles';
import { getDate } from '../../libs/utils/formatDate';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { IAdminUser } from '../../redux/admin-users/types';

enum AdminUserRole {
  Admin = 1,
  User = 2,
  Officer = 3,
}

const useStyles = makeStyles({
  tableCellText: {
    color: `${SECOND_LIGHT_GRAY} !important`,
    fontSize: `${TABLE_CELL} !important`,
  },

  icon: {
    color: MAIN_COLOR,
    '&:hover': {
      color: MAIN_LIGHT_COLOR,
      cursor: 'pointer',
    },
  },
});

interface Props {
  data: IAdminUser[];
  onChangeRole: (role: number, adminUserId: number) => void;
}

const TableBody = ({ data, onChangeRole }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [role, setRole] = useState<string>('1');
  const styles = useStyles();

  const handleChange = (role: number, adminUserId: number) => {
    onChangeRole(role, adminUserId);
  };

  return (
    <>
      {data?.map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          <TableCell
            align="center"
            key="dental_registration_number"
            className={styles.tableCellText}
          >
            {row['dental_registration_number']}
          </TableCell>
          <TableCell align="center" key="name" className={styles.tableCellText}>
            {row['name']}
          </TableCell>
          <TableCell
            align="center"
            key="email"
            className={styles.tableCellText}
          >
            {row['email']}
          </TableCell>
          <TableCell
            align="center"
            key="birth_date"
            className={styles.tableCellText}
          >
            {getDate(row['birth_date'].toString())}
          </TableCell>
          <TableCell
            align="center"
            key="action"
            className={styles.tableCellText}
          >
            <FormControl sx={{ width: 85, height: 37 }} size="small">
              <Select
                value={row.role.toString()}
                onChange={(event: SelectChangeEvent) => {
                  handleChange(parseInt(event.target.value), row.admin_user_id);
                }}
                displayEmpty
                style={{
                  borderRadius: '10px',
                  fontSize: '12px',
                  color: GRAY,
                }}
              >
                <MenuItem
                  value={AdminUserRole.Admin}
                  sx={{ fontSize: '12px', color: GRAY }}
                >
                  {AdminUserRole[1]}
                </MenuItem>
                <MenuItem
                  value={AdminUserRole.User}
                  sx={{ fontSize: '12px', color: GRAY }}
                >
                  {AdminUserRole[2]}
                </MenuItem>
                <MenuItem
                  value={AdminUserRole.Officer}
                  sx={{ fontSize: '12px', color: GRAY }}
                >
                  {AdminUserRole[3]}
                </MenuItem>
              </Select>
            </FormControl>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
export default TableBody;
