/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { IUserType, translate } from '../../libs/utils';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MAIN_LIGHT_COLOR, WHITE } from '../../themes/colors';
import { IArea } from '../../redux/areas/types';
import { useSelector } from 'react-redux';
import { areasSelector } from '../../redux/areas/selectors';
import { useAppDispatch } from '../../redux';
import { getAreas } from '../../redux/areas/action';
import Button from '../../components/Button';
import { useForm } from 'react-hook-form';
import FormProvider from '../../components/FormProvider';
import {
  clearState,
  createMember,
  getDetailMember,
  updateMember,
} from '../../redux/members';
import { ICreateMember } from '../../libs/apis/members/types';
import { useNavigate, useParams } from 'react-router-dom';
import { errorsSelector, memberSelector } from '../../redux/members/selectors';
import { IError, IMembers } from '../../redux/members/types';
import CreatePopup from '../../components/popup/CreatePopup';
import ErrorMessage from '../../components/ErrorMessage';
import { UserType } from '../../configs';
import { TOPIC_LABEL } from '../../themes/fonts';
import EditPopup from '../../components/popup/EditPopup';

const useStyles = makeStyles({
  container: {
    maxWidth: '960px',
    marginLeft: '32px',
    marginRight: '32px',
    height: '92px',
  },

  labelContainer: {
    minWidth: '240px',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },

  textLabel: {
    color: WHITE,
    fontSize: `${TOPIC_LABEL} !important`,
    fontWeight: 'bold !important',
  },

  selectContainer: {
    width: '160px',
    marginLeft: '40px',
  },

  customSelectInput: {
    borderRadius: '12px !important',
    height: '50px !important',
  },
});

const defaultValue: ICreateMember = {
  furigana_name: '',
  kanji_name: '',
  email: '',
  area_id: -1,
  dental_registration_number: '',
  birth_date: '',
  user_type: -1,
};

const memberTypes: IUserType[] = [
  {
    value: UserType.REGULAR,
    type: translate('member.list.table.membership_type.regular'),
  },
  {
    value: UserType.ASSOCIATE,
    type: translate('member.list.table.membership_type.associate'),
  },
  {
    value: UserType.ADMIN_STAFF,
    type: translate('member.list.table.membership_type.admin_staff'),
  },
];

const CreateMemberScreen: React.FC = () => {
  const { id } = useParams();

  const styles = useStyles();
  const navigate = useNavigate();
  const [areas, setAreas] = useState<IArea[]>([]);
  const [dataAdd, setDataAdd] = useState<ICreateMember>(defaultValue);
  const [detailMember, setDetailMember] = useState<IMembers>();
  const [errorsMessage, setErrorsMessage] = useState<IError | undefined>({});
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const methods = useForm();
  const { handleSubmit } = methods;

  const data: IArea[] = useSelector(areasSelector);
  const errors: IError | undefined = useSelector(errorsSelector);
  const detail: IMembers = useSelector(memberSelector);

  useEffect(() => {
    dispatch(clearState());
    dispatch(getAreas());
    if (id) dispatch(getDetailMember(id));
  }, [dispatch, id]);

  useEffect(() => {
    setAreas(data);
  }, [data]);

  const onSubmit = handleSubmit(async () => {
    const formattedDate = dataAdd.birth_date.replace(/\//g, '');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let response: any;
    if (id) {
      response = await dispatch(
        updateMember({
          memberData: { ...dataAdd, birth_date: formattedDate },
          id,
        }),
      );
    } else {
      response = await dispatch(
        createMember({ ...dataAdd, birth_date: formattedDate }),
      );
    }
    if (response.payload.success == true) {
      if (id) {
        setOpenEditModal(true);
      } else {
        setOpenCreateModal(true);
      }
      dispatch(clearState());
    }
  });

  useEffect(() => {
    setErrorsMessage(errors);
  }, [errors]);

  useEffect(() => {
    if (id && detail.user_id) {
      setDetailMember(detail);
      if (detailMember) {
        const memberValue: ICreateMember = {
          furigana_name: detailMember.furigana_name,
          kanji_name: detailMember.kanji_name,
          email: detailMember.email,
          area_id: detailMember.area.area_id,
          dental_registration_number: detailMember.dental_registration_number,
          birth_date: detailMember.birth_date.toString(),
          user_type: detailMember.user_type,
        };
        setDataAdd({ ...memberValue });
      }
    }
  }, [detail, detailMember, id]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    setDataAdd({
      ...dataAdd,
      [key]: e.target.value,
    });
    const messages = { ...errorsMessage };
    if (key == 'kanji_name' && messages?.kanji_name) delete messages.kanji_name;
    if (key == 'furigana_name' && messages?.furigana_name)
      delete messages.furigana_name;
    if (key == 'email' && messages?.email) delete messages.email;
    if (
      key == 'dental_registration_number' &&
      messages?.dental_registration_number
    )
      delete messages.dental_registration_number;
    if (key == 'birth_date') {
      if (messages?.birth_date) delete messages.birth_date;

      let value = e.target.value.replace(/\D/g, '');
      if (value.length > 4) {
        value = value.slice(0, 4) + '/' + value.slice(4);
      }
      if (value.length > 7) {
        value = value.slice(0, 7) + '/' + value.slice(7);
      }
      setDataAdd({
        ...dataAdd,
        [key]: value,
      });
    }
    setErrorsMessage(messages);
  };

  const handleSelectArea = (event: SelectChangeEvent) => {
    const messages = { ...errorsMessage };
    if (messages?.area_id) {
      delete messages.area_id;
      setErrorsMessage(messages);
    }
    setDataAdd({
      ...dataAdd,
      area_id: parseInt(event.target.value),
    });
  };

  const handleSelectMembershipType = (event: SelectChangeEvent) => {
    const messages = { ...errorsMessage };
    if (messages?.user_type) {
      delete messages.user_type;
      setErrorsMessage(messages);
    }
    setDataAdd({
      ...dataAdd,
      user_type: parseInt(event.target.value),
    });
  };

  const handleBackButton = () => navigate('/members');

  return (
    <Layout title={translate(id ? 'member.edit.title' : 'member.create.title')}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('member.create.label.full_name')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              sx={{ width: '100%' }}
              id="kanji_name"
              name="kanji_name"
              type="text"
              variant="standard"
              value={dataAdd.kanji_name}
              error={!!errorsMessage?.kanji_name ?? false}
              onChange={handleOnChange}
            />
            {errorsMessage?.kanji_name &&
              errorsMessage.kanji_name.length > 0 && (
                <Box sx={{ marginTop: '14px' }}>
                  <ErrorMessage message={errorsMessage.kanji_name[0]} />
                </Box>
              )}
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('member.create.label.furigana')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              sx={{ width: '100%' }}
              id="furigana_name"
              name="furigana_name"
              type="text"
              variant="standard"
              value={dataAdd.furigana_name}
              error={!!errorsMessage?.furigana_name ?? false}
              onChange={handleOnChange}
            />
            {errorsMessage?.furigana_name &&
              errorsMessage.furigana_name.length > 0 && (
                <Box sx={{ marginTop: '14px' }}>
                  <ErrorMessage message={errorsMessage.furigana_name[0]} />
                </Box>
              )}
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('member.create.label.email')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              sx={{ width: '100%' }}
              id="email"
              name="email"
              type="text"
              variant="standard"
              error={!!errorsMessage?.email ?? false}
              value={dataAdd.email}
              onChange={handleOnChange}
            />
            {errorsMessage?.email && errorsMessage.email.length > 0 && (
              <Box sx={{ marginTop: '14px' }}>
                <ErrorMessage message={errorsMessage.email[0]} />
              </Box>
            )}
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('member.create.label.membership_type')}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: '40px' }}>
            <FormControl
              className={styles.selectContainer}
              sx={{ marginTop: '12px' }}
            >
              <InputLabel id="membership-type-select-label">
                {translate('member.create.label.membership_type.select')}
              </InputLabel>
              <Select
                labelId="membership-type-select-label"
                id="membership-type-select"
                label={translate('member.create.label.membership_type.select')}
                className={styles.customSelectInput}
                onChange={handleSelectMembershipType}
                value={dataAdd.user_type.toString()}
                error={!!errorsMessage?.user_type ?? false}
              >
                {memberTypes.map((type: IUserType) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorsMessage?.user_type && errorsMessage.user_type.length > 0 && (
              <Box sx={{ marginTop: '14px' }}>
                <ErrorMessage message={errorsMessage.user_type[0]} />
              </Box>
            )}
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('member.create.label.target_area')}
            </Typography>
          </Box>
          <Box className={styles.selectContainer}>
            <FormControl fullWidth sx={{ marginTop: '12px' }}>
              <InputLabel id="target-area-select-label">
                {translate('member.create.label.target_area.select')}
              </InputLabel>
              <Select
                labelId="target-area-select-label"
                id="target-area-select"
                label={translate('member.create.label.target_area.select')}
                className={styles.customSelectInput}
                onChange={handleSelectArea}
                value={dataAdd.area_id.toString()}
                error={!!errorsMessage?.area_id ?? false}
              >
                {areas.map((area: IArea) => (
                  <MenuItem key={area.area_id} value={area.area_id}>
                    {area.area_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorsMessage?.area_id && errorsMessage.area_id.length > 0 && (
              <Box sx={{ marginTop: '14px' }}>
                <ErrorMessage message={errorsMessage.area_id[0]} />
              </Box>
            )}
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('member.create.label.id')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              sx={{ width: '100%' }}
              id="dental_registration_number"
              name="dental_registration_number"
              type="text"
              variant="standard"
              value={dataAdd.dental_registration_number}
              error={!!errorsMessage?.dental_registration_number ?? false}
              onChange={handleOnChange}
            />
            {errorsMessage?.dental_registration_number &&
              errorsMessage.dental_registration_number.length > 0 && (
                <Box sx={{ marginTop: '14px' }}>
                  <ErrorMessage
                    message={errorsMessage.dental_registration_number[0]}
                  />
                </Box>
              )}
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('member.create.label.date_of_birth')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              sx={{ width: '100%' }}
              id="birth_date"
              name="birth_date"
              type="text"
              variant="standard"
              value={dataAdd.birth_date.toString()}
              error={!!errorsMessage?.birth_date ?? false}
              onChange={handleOnChange}
              inputProps={{ maxLength: 10 }}
            />
            {errorsMessage?.birth_date &&
              errorsMessage.birth_date.length > 0 && (
                <Box sx={{ marginTop: '14px' }}>
                  <ErrorMessage message={errorsMessage.birth_date[0]} />
                </Box>
              )}
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="center"
          gap={5}
          sx={{ marginY: '40px' }}
        >
          <Button
            type="submit"
            label={translate(
              id ? 'button.edit' : 'member.create.button.create',
            )}
            sx={{
              width: '100px',
              height: '38px',
              borderRadius: 0,
              boxShadow: 0,
            }}
            textSx={{ fontSize: '14px', fontWeight: 'bold' }}
          />
          <Button
            label={translate('member.create.button.back')}
            onClick={() => handleBackButton()}
            sx={{
              width: '100px',
              height: '38px',
              borderRadius: 0,
              boxShadow: 0,
            }}
            textSx={{ fontSize: '14px', fontWeight: 'bold' }}
          />
        </Stack>
      </FormProvider>
      <CreatePopup
        open={openCreateModal}
        onConfirm={() => navigate('/members')}
        onClose={() => navigate('/members')}
      />
      <EditPopup
        open={openEditModal}
        onConfirm={() => navigate('/members')}
        onClose={() => navigate('/members')}
      />
    </Layout>
  );
};

export default CreateMemberScreen;
