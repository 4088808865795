import { Dayjs } from 'dayjs';
import { FC } from 'react';
import { DatePicker as MDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';
import ErrorMessage from './ErrorMessage';

interface Props {
  label: string;
  dateValue: Dayjs;
  onChange: (date: Dayjs) => void;
  errorMessage?: string;
}

const DatePicker: FC<Props> = ({
  label,
  dateValue,
  onChange,
  errorMessage,
}) => {
  const handleOnChangedDate = (date: Dayjs | null) => {
    if (date) onChange(date);
  };
  return (
    <>
      <MDatePicker
        label={label}
        value={dateValue}
        format="MMM DD, YYYY"
        sx={{ width: '173px' }}
        onChange={handleOnChangedDate}
      />
      {errorMessage && (
        <Box sx={{ marginTop: '14px' }}>
          <ErrorMessage message={errorMessage} />
        </Box>
      )}
    </>
  );
};

export default DatePicker;
