import {
  Box,
  Pagination as MPagination,
  PaginationItem,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {
  ARROW_COLOR,
  MAIN_LIGHT_COLOR,
  SECOND_LIGHT_GRAY,
  WHITE,
} from '../../themes/colors';
import { TABLE_HEADER } from '../../themes/fonts';

interface Props {
  total: number;
  page: number;
  textTotal?: string;
  onChangePage: (page: number) => void;
}

const Pagination: React.FC<Props> = ({
  total,
  page,
  textTotal,
  onChangePage,
}) => {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginTop: '30px', marginBottom: '40px' }}
    >
      <Box></Box>
      <MPagination
        sx={{
          '& .MuiPaginationItem-root': {
            borderRadius: '6px',
            margin: 0,
            fontSize: TABLE_HEADER,
            '&.Mui-selected': {
              backgroundColor: MAIN_LIGHT_COLOR,
              color: WHITE,
            },
          },
        }}
        onChange={(event: React.ChangeEvent<unknown>, page: number) => {
          onChangePage(page);
        }}
        count={total}
        page={page}
        renderItem={(item) => {
          return (
            <PaginationItem
              {...item}
              slots={{
                previous: () => (
                  <ArrowBackIosNewRoundedIcon
                    sx={{ color: ARROW_COLOR, fontSize: '16px' }}
                  />
                ),
                next: () => (
                  <ArrowForwardIosRoundedIcon
                    sx={{ color: ARROW_COLOR, fontSize: '16px' }}
                  />
                ),
              }}
            />
          );
        }}
      />
      <Typography sx={{ fontSize: TABLE_HEADER, color: SECOND_LIGHT_GRAY }}>
        {textTotal}
      </Typography>
    </Stack>
  );
};
export default Pagination;
