import { Checkbox, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { translate } from '../../libs/utils';
import { NOTICE } from '../../themes/colors';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

interface Props {
  isSelectAllAreas: boolean;
  setIsSelectAllAreas: () => void;
}

const SelectAllAreasField: FC<Props> = ({
  isSelectAllAreas,
  setIsSelectAllAreas,
}) => {
  return (
    <Stack flexDirection="row">
      <Checkbox
        icon={<RadioButtonUncheckedIcon />}
        checkedIcon={<RadioButtonCheckedIcon />}
        checked={isSelectAllAreas}
        onClick={() => setIsSelectAllAreas()}
      />
      <Typography
        sx={{ fontSize: '12px', color: NOTICE, fontWeight: '600' }}
        alignContent={'center'}
      >
        {translate('questionnaire.create_edit.item.all_areas')}
      </Typography>
    </Stack>
  );
};

export default SelectAllAreasField;
