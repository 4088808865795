/* eslint-disable no-undef */
import { Box, Stack, TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { BLACK } from '../themes/colors';
import { TITLE_LINE_HEIGHT, TOPIC_INPUT_VALUE } from '../themes/fonts';
import ErrorMessage from './ErrorMessage';

interface Props {
  value: string;
  multiline: boolean;
  rows: number;
  label?: string;
  setText: (value: string) => void;
  errorMessage?: string[] | undefined;
  name: string;
}

const FormTextField: FC<Props> = ({
  value,
  multiline = false,
  rows = 1,
  setText,
  label,
  errorMessage,
  name,
}) => {
  return (
    <Stack flexDirection={'column'}>
      <TextField
        sx={{
          width: '1000px',
          color: BLACK,
          fontSize: TOPIC_INPUT_VALUE,
          lineHeight: TITLE_LINE_HEIGHT,
        }}
        name={name}
        variant={multiline ? 'outlined' : 'standard'}
        multiline={multiline}
        value={value}
        rows={rows}
        type="text"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setText(event.target.value);
        }}
        label={label}
        error={errorMessage ? true : false}
      />
      {errorMessage && (
        <Box sx={{ marginTop: '14px' }}>
          <ErrorMessage
            message={errorMessage.length > 0 ? errorMessage[0] : ''}
          />
        </Box>
      )}
    </Stack>
  );
};

export default FormTextField;
