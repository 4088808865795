import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { IHeader, translate } from '../../libs/utils';
import { Box } from '@mui/material';
import TableButton from '../../components/table/TableButton';
import Table from '../../components/table/Table';
import TableBody from '../../components/safety/TableBody';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux';
import { getAllSafety } from '../../redux/safety/action';
import { useSelector } from 'react-redux';
import { allSafetySelector } from '../../redux/safety/selectors';
import { IData, ISafety } from '../../redux/safety/types';
import Pagination from '../../components/table/Pagination';
import { calculateTotalPage } from '../../libs/utils/calculateTotalPage';

const headers: IHeader[] = [
  { id: 'title', label: translate('safety.list.table.title') },
  { id: 'target_area', label: translate('safety.list.table.target_area') },
  { id: 'detail', label: translate('safety.list.table.detail') },
  { id: 'post_date', label: translate('safety.list.table.post_date') },
  { id: 'detail1', label: translate('safety.list.table.detail') },
];

const AllSafetyScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [allSafety, setAllSafety] = useState<ISafety[]>([]);
  const [total, setTotal] = useState<number>(0);

  const data: IData = useSelector(allSafetySelector);

  const handleOnClickSafetyDetail = (safetyId: number) =>
    navigate(`${safetyId}`);

  useEffect(() => {
    dispatch(
      getAllSafety({
        page: currentPage,
      }),
    );
  }, [dispatch, currentPage]);

  useEffect(() => {
    setAllSafety(data.items);
    setCurrentPage(data.current_page);
    setTotal(data.total);
  }, [data.current_page, data.items, data.total]);

  const handleOnChangePage = (page: number) => setCurrentPage(page);

  const handleRedirectToCreatePage = () => navigate('create');

  return (
    <Layout title={translate('safety.list.title')}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          bgcolor: 'background.paper',
          py: 2,
          borderRadius: 1,
          mb: '5px',
          mt: '-35px',
        }}
      >
        <TableButton
          label={translate('button.add')}
          onClick={handleRedirectToCreatePage}
        />
      </Box>
      <Table headers={headers}>
        <TableBody data={allSafety} onClickDetail={handleOnClickSafetyDetail} />
      </Table>
      <Pagination
        total={calculateTotalPage(total)}
        page={currentPage}
        onChangePage={handleOnChangePage}
      />
    </Layout>
  );
};

export default AllSafetyScreen;
