/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSdk } from '../../libs/apis';
import { ICreateMemberState, IMembersState, IMemberState } from './types';
import { ICreateMember, IMemberLocal } from '../../libs/apis/members/types';

export const getMembers = createAsyncThunk(
  '/admin/members',
  async (memberData: IMemberLocal, { rejectWithValue }) => {
    try {
      const data: IMembersState =
        await apiSdk.memberApis.getMembers(memberData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getDetailMember = createAsyncThunk(
  '/admin/member',
  async (memberId: string, { rejectWithValue }) => {
    try {
      const data: IMemberState = await apiSdk.memberApis.getMember(memberId);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const createMember = createAsyncThunk(
  '/admin/members/create',
  async (memberData: ICreateMember, { rejectWithValue }) => {
    try {
      const data: ICreateMemberState =
        await apiSdk.memberApis.createMember(memberData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateMember = createAsyncThunk(
  '/admin/members/update',
  async (
    data: { memberData: ICreateMember; id: string },
    { rejectWithValue },
  ) => {
    const { memberData, id } = data;
    try {
      const data: ICreateMemberState = await apiSdk.memberApis.updateMember(
        memberData,
        id,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
