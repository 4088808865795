import { FC } from 'react';
import { Checkbox as MCheckbox, FormControlLabel } from '@mui/material';

interface Props {
  checked: boolean;
  label: string;
  onClick: () => void;
}

const Checkbox: FC<Props> = ({ checked, label, onClick }: Props) => {
  return (
    <FormControlLabel
      control={<MCheckbox checked={checked} />}
      label={label}
      onClick={onClick}
    />
  );
};

export default Checkbox;
