import { Box, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FC } from 'react';
import { translate } from '../../libs/utils';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import { Dayjs } from 'dayjs';
import ErrorMessage from '../ErrorMessage';
import { IAdditionalDate } from '../../libs/apis/seminars/types';
import { AdditionalDay } from '../../configs';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
  index: number;
  additionalDate: IAdditionalDate;
  setAdditionalDate?: (
    index: number,
    value: Dayjs,
    valueType: AdditionalDay,
  ) => void;
  onRemove?: (index: number) => void;
  errorMessage?: string[] | undefined;
}

const AdditionalDayFields: FC<Props> = ({
  index,
  additionalDate,
  setAdditionalDate,
  onRemove,
  errorMessage,
}) => {
  const handleSetDate = (value: Dayjs) => {
    setAdditionalDate?.(index, value, AdditionalDay.DATE);
  };

  const handleSetStarTime = (value: Dayjs) => {
    setAdditionalDate?.(index, value, AdditionalDay.START_TIME);
  };
  const handleSetEndTime = (value: Dayjs) => {
    setAdditionalDate?.(index, value, AdditionalDay.END_TIME);
  };

  const handleRemoveAdditionalDate = () => {
    onRemove?.(index);
  };

  return (
    <Stack flexDirection={'column'}>
      <Stack flexDirection="row" alignItems="left" gap={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={translate('seminar.detail.item.start_date')}
            dateValue={additionalDate.date}
            onChange={handleSetDate}
          />
          <TimePicker
            label={translate('seminar.detail.item.start_time')}
            value={additionalDate.start_time}
            onChange={handleSetStarTime}
          />
          <TimePicker
            label={translate('seminar.detail.item.end_time')}
            value={additionalDate.end_time}
            onChange={handleSetEndTime}
          />
        </LocalizationProvider>
        <Stack justifyContent={'center'}>
          <DeleteIcon color={'warning'} onClick={handleRemoveAdditionalDate} />
        </Stack>
      </Stack>
      {errorMessage && (
        <Box sx={{ marginTop: '14px' }}>
          <ErrorMessage
            message={errorMessage.length > 0 ? errorMessage[0] : ''}
          />
        </Box>
      )}
    </Stack>
  );
};

export default AdditionalDayFields;
