/* eslint-disable @typescript-eslint/no-explicit-any */
import { RootState } from '..';
import { ICreateSafetyError, IData, ISafety } from './types';

export const allSafetySelector = (state: RootState): IData =>
  state.safety.list.data;

export const safetySelector = (state: RootState): ISafety =>
  state.safety.detail.data;

export const errorsSelector = (
  state: RootState,
): ICreateSafetyError | undefined => state.safety.errors;

export const exportCsvSelector = (state: RootState): any =>
  state.safety.exportCsv.data;
