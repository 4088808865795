import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { translate } from '../../libs/utils';
import Table from '../../components/table/Table';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux';
import { seminarsSelector } from '../../redux/seminars/selectors';
import { getSeminars } from '../../redux/seminars/action';
import { IData } from '../../redux/seminars/types';
import Pagination from '../../components/table/Pagination';
import TableBody from '../../components/seminars/TableBody';
import { calculateTotalPage } from '../../libs/utils/calculateTotalPage';
import TableButton from '../../components/table/TableButton';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const headers = [
  { id: 'seminar_title', label: translate('seminar.list.table.seminar_title') },
  { id: 'organizer', label: translate('seminar.list.table.organizer') },
  { id: 'target', label: translate('seminar.list.table.target') },
  { id: 'date', label: translate('seminar.list.table.date') },
  { id: 'period', label: translate('seminar.list.table.period') },
  { id: 'status', label: translate('seminar.list.table.status') },
  { id: 'detail', label: translate('seminar.list.table.detail') },
  { id: 'edit', label: translate('seminar.list.table.edit') },
];

const AllSeminarScreen: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data: IData = useSelector(seminarsSelector);

  const redirectToCreateScreen = () => navigate('/seminars/create');

  const handleOnChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleNavigateToDetailScreen = (seminarId: number) => {
    navigate(`/seminars/${seminarId}`);
  };

  const handleNavigateToEditScreen = (seminarId: number) => {
    navigate(`/seminars/${seminarId}/edit`);
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getSeminars({ page: currentPage }));
    // eslint-disable-next-line no-undef
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [currentPage, dispatch]);

  return (
    <Layout title={translate('seminar.list.title')}>
      {isLoading && (
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Stack>
      )}
      {!isLoading && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              bgcolor: 'background.paper',
              py: 2,
              borderRadius: 1,
              mb: '5px',
              mt: '-35px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TableButton
                label={translate('button.add')}
                onClick={redirectToCreateScreen}
              />
            </Box>
          </Box>
          <Table headers={headers}>
            <TableBody
              data={data.seminars}
              onClickDetail={handleNavigateToDetailScreen}
              onClickEdit={handleNavigateToEditScreen}
            ></TableBody>
          </Table>
          <Pagination
            total={calculateTotalPage(data.total)}
            page={data.current_page}
            textTotal={`${translate('table.total.label')} ${data.total}${translate('seminar.list.table.paginate.label')}`}
            onChangePage={handleOnChangePage}
          />
        </>
      )}
    </Layout>
  );
};
export default AllSeminarScreen;
