import { Dayjs } from 'dayjs';
import { FC } from 'react';
import { TimePicker as MTimePicker } from '@mui/x-date-pickers/TimePicker';
import { Box } from '@mui/material';
import ErrorMessage from './ErrorMessage';

interface Props {
  label: string;
  value: Dayjs;
  onChange: (time: Dayjs) => void;
  errorMessage?: string;
}

const TimePicker: FC<Props> = ({
  label,
  value,
  onChange,
  errorMessage,
}: Props) => {
  const handleOnChangedTime = (time: Dayjs | null) => {
    if (time) onChange(time);
  };
  return (
    <>
      <MTimePicker
        ampm={false}
        label={label}
        value={value}
        sx={{ width: '173px' }}
        onChange={handleOnChangedTime}
      />
      {errorMessage && (
        <Box sx={{ marginTop: '14px' }}>
          <ErrorMessage message={errorMessage} />
        </Box>
      )}
    </>
  );
};

export default TimePicker;
