import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Stack,
  FormControlLabel,
  Switch,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Layout from '../../components/Layout';
import { useAppDispatch } from '../../redux';
import { translate } from '../../libs/utils';
import { MAIN_LIGHT_COLOR, WHITE } from '../../themes/colors';
import TableButton from '../../components/table/TableButton';
import FormTextField from '../../components/FormTextField';
import { getAreas } from '../../redux/areas/action';
import { HTTP_STATUS } from '../../configs';
import LabelField from '../../components/seminars/LabelField';
import FormProvider from '../../components/FormProvider';
import { useForm } from 'react-hook-form';
import ErrorMessage from '../../components/ErrorMessage';
import Button from '../../components/Button';
import { useSelector } from 'react-redux';
import CreatePopup from '../../components/popup/CreatePopup';
import EditPopup from '../../components/popup/EditPopup';
import {
  questionnaireSelector,
  errorsSelector,
  editQuestionnaireSelector,
  createQuestionnaireSelector,
} from '../../redux/questionnaires/selectors';
import {
  createQuestionnaire,
  editQuestionnaire,
  getQuestionnaire,
} from '../../redux/questionnaires/action';
import { clearState } from '../../redux/questionnaires';
import {
  IQuestionnaireErrorState,
  IQuestionnaireState,
} from '../../redux/questionnaires/types';
import { IArea } from '../../redux/areas/types';
import TargetAreasField from '../../components/questionnaire/TargetAreasField';
import { IQuestionnaire } from '../../libs/apis/questionnaires/types';
import { areasSelector } from '../../redux/areas/selectors';

const useStyles = makeStyles({
  container: {
    maxWidth: '1226',
    margin: '0 32px',
  },
  labelContainer: {
    minWidth: '240px',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  textLabel: {
    color: WHITE,
    fontSize: '14px !important',
    fontWeight: 'bold !important',
  },
  lectureText: {
    '& .MuiInputBase-input': {
      fontSize: '15px',
    },
  },
  height70: { height: '70px' },
  height83: { height: '83px' },
  height140: { height: '140px' },
  height170: { height: '170px' },
});

const CreateScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const { id } = useParams();
  const methods = useForm();
  const { handleSubmit } = methods;

  const errors = useSelector(errorsSelector);
  const editQuestionnaireResult = useSelector(editQuestionnaireSelector);
  const createQuestionnaireResult = useSelector(createQuestionnaireSelector);
  const questionnaire: IQuestionnaireState = useSelector(questionnaireSelector);
  const allAreas: IArea[] = useSelector(areasSelector);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('');
  const [detail, setDetail] = useState<string>('');
  const [url, setURL] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAllAreas, setIsAllAreas] = useState<boolean>(false);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [isShowEditPopup, setIsShowEditPopup] = useState<boolean>(false);
  const [areas, setAreas] = useState<IArea[]>([]);
  const [areasCached, setAreasCached] = useState<IArea[]>([]);
  const [errorsMessage, setErrorsMessage] = useState<
    IQuestionnaireErrorState | undefined
  >();

  const handleClearState = () => {
    dispatch(clearState());
    navigate('/questionnaires');
  };

  const handleBackButton = () => {
    handleClearState();
  };

  const handleCloseCreatePopup = () => {
    setIsShowPopup(false);
    handleClearState();
  };

  const handleCloseEditPopup = () => {
    setIsShowEditPopup(false);
    handleClearState();
  };

  const onSubmit = handleSubmit(async () => {
    setErrorsMessage(undefined);
    dispatch(clearState());
    const questionnaireData = {
      title,
      detail,
      url,
      isOpen,
      all_areas: isAllAreas,
      target_area: areas.map((area: IArea) => area.area_id),
    } as unknown as IQuestionnaire;

    if (id) {
      dispatch(
        editQuestionnaire({
          questionnaire_id: parseInt(id),
          ...questionnaireData,
        }),
      );
    } else dispatch(createQuestionnaire(questionnaireData));
  });

  useEffect(() => {
    dispatch(getAreas());
    if (id) dispatch(getQuestionnaire(parseInt(id)));
  }, [dispatch, id]);

  useEffect(() => {
    if (questionnaire.data && id && allAreas.length) {
      setIsOpen(questionnaire.data.isOpen);
      setTitle(questionnaire.data.title);
      setDetail(questionnaire.data.detail);
      setURL(questionnaire.data.url);
      if (questionnaire.data.target_area.length !== areasCached.length) {
        setAreas(questionnaire.data.target_area as IArea[]);
        setAreasCached(questionnaire.data.target_area as IArea[]);
      }

      if (areas.length === allAreas.length) {
        setIsAllAreas(true);
      } else {
        setIsAllAreas(false);
      }
    }

    // eslint-disable-next-line no-undef
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [
    allAreas.length,
    areas,
    areasCached.length,
    id,
    isAllAreas,
    questionnaire.data,
  ]);

  useEffect(() => {
    if (errors) {
      setErrorsMessage(errors);
      return;
    }

    if (
      createQuestionnaireResult.status === HTTP_STATUS.OK &&
      createQuestionnaireResult.success
    ) {
      setErrorsMessage(undefined);
      setIsShowPopup(true);
      return;
    }

    if (
      id &&
      editQuestionnaireResult.status === HTTP_STATUS.OK &&
      editQuestionnaireResult.success
    ) {
      setErrorsMessage(undefined);
      setIsShowEditPopup(true);
      return;
    }
  }, [
    allAreas,
    areas,
    createQuestionnaireResult.status,
    createQuestionnaireResult.success,
    editQuestionnaireResult.status,
    editQuestionnaireResult.success,
    errors,
    id,
    questionnaire,
  ]);

  return (
    <Layout
      title={translate(
        id ? 'questionnaire.edit.title' : 'questionnaire.create.title',
      )}
    >
      {isLoading && (
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Stack>
      )}
      {!isLoading && (
        <FormProvider methods={{ ...methods }} onSubmit={onSubmit}>
          <LabelField
            label={translate('questionnaire.create_edit.header')}
            content={
              <FormTextField
                name="header"
                value={title}
                setText={setTitle}
                multiline={false}
                rows={1}
                errorMessage={errorsMessage?.title}
              />
            }
            height={styles.height83}
          />
          <LabelField
            label={translate('questionnaire.create_edit.detail')}
            content={
              <FormTextField
                name="detail"
                value={detail}
                setText={setDetail}
                multiline={true}
                rows={4}
                label={'Label'}
                errorMessage={errorsMessage?.detail}
              />
            }
            height={styles.height140}
          />
          <LabelField
            label={translate('questionnaire.create_edit.url')}
            content={
              <FormTextField
                name={'url'}
                value={url}
                setText={setURL}
                multiline={false}
                rows={1}
                errorMessage={errorsMessage?.url}
              />
            }
            height={styles.height83}
          />
          <LabelField
            label={translate('questionnaire.create_edit.target_area')}
            content={
              <Stack flexDirection={'column'}>
                <TargetAreasField
                  allAreas={allAreas}
                  areas={areas}
                  isSelectAllAreas={isAllAreas}
                  setIsSelectAllAreas={setIsAllAreas}
                  setAreas={setAreas}
                />
                {errorsMessage?.target_area && (
                  <Box sx={{ marginTop: '14px' }}>
                    <ErrorMessage
                      message={
                        errorsMessage?.target_area.length > 0
                          ? errorsMessage?.target_area[0]
                          : ''
                      }
                    />
                  </Box>
                )}
                {errorsMessage?.all_areas && (
                  <Box sx={{ marginTop: '14px' }}>
                    <ErrorMessage
                      message={
                        errorsMessage?.all_areas.length > 0
                          ? errorsMessage?.all_areas[0]
                          : ''
                      }
                    />
                  </Box>
                )}
              </Stack>
            }
            height={styles.height170}
          />
          <LabelField
            label={translate('questionnaire.create_edit.publicity')}
            content={
              <Stack>
                <Box sx={{ flexGrow: 1 }}>
                  <FormControlLabel
                    control={<Switch checked={isOpen} />}
                    label={translate(
                      'questionnaire.create_edit.item.publicity',
                    )}
                    onClick={() => setIsOpen(!isOpen)}
                  />
                </Box>
                {errorsMessage?.isOpen && (
                  <Box sx={{ marginTop: '14px' }}>
                    <ErrorMessage
                      message={
                        errorsMessage?.isOpen.length > 0
                          ? errorsMessage?.isOpen[0]
                          : ''
                      }
                    />
                  </Box>
                )}
              </Stack>
            }
            height={styles.height70}
          />
          <Stack
            flexDirection="row"
            justifyContent="center"
            gap={5}
            sx={{ marginY: '40px' }}
          >
            <Button
              type="submit"
              label={id ? translate('button.edit') : translate('button.create')}
              sx={{
                width: '40px',
                height: '38px',
                borderRadius: 0,
                boxShadow: 0,
              }}
              textSx={{ fontSize: '14px', fontWeight: 'bold' }}
            />
            <TableButton
              label={translate('button_cancel')}
              onClick={handleBackButton}
            />
          </Stack>
        </FormProvider>
      )}
      {isShowPopup && (
        <CreatePopup
          onConfirm={handleCloseCreatePopup}
          onClose={handleCloseCreatePopup}
          open={isShowPopup}
        />
      )}
      {isShowEditPopup && (
        <EditPopup
          onConfirm={handleCloseEditPopup}
          onClose={handleCloseEditPopup}
          open={isShowEditPopup}
        />
      )}
    </Layout>
  );
};

export default CreateScreen;
