import { FC, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import Modal from '@mui/material/Modal';
import { WHITE } from '../../themes/colors';
import { makeStyles } from '@mui/styles';
import Title from './Title';
import Content from './Content';

interface Props {
  title: string;
  content: string;
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles({
  container: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '314px',
    height: '120px',
    backgroundColor: WHITE,
    borderWidth: '0px',
    borderRadius: '10px',
    boxShadow: '15px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 1,
  },
});

const Layout: FC<Props> = ({
  title,
  content,
  isOpen,
  onClose,
  children,
}: Props) => {
  const styles = useStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box className={styles.container}>
          <Stack direction={'column'} gap={2}>
            <Title title={title} />
            <Content content={content} />
            <Box className={styles.buttonContainer}>{children}</Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default Layout;
