/* eslint-disable no-undef */
import { FC, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { translate } from '../../libs/utils';
import Checkbox from '../Checkbox';
import { BLACK } from '../../themes/colors';
import { TOPIC_LABEL } from '../../themes/fonts';

enum SeminarType {
  LOCAL = 0,
  ONLINE = 1,
  ALL = 2,
}
interface Props {
  seminarType: number;
  setSeminarType: (type: number) => void;
}

const EventTypeField: FC<Props> = ({ seminarType, setSeminarType }) => {
  const [isLocalEvent, setIsLocalEvent] = useState<boolean>(
    seminarType !== SeminarType.ONLINE,
  );
  const [isOnlineEvent, setIsOnlineEvent] = useState<boolean>(
    seminarType !== SeminarType.LOCAL,
  );

  const handleClickLocalEventOptions = () => {
    setIsLocalEvent(!isLocalEvent);
  };

  const handleClickOnlineEventOptions = () => {
    setIsOnlineEvent(!isOnlineEvent);
  };

  useEffect(() => {
    if (isLocalEvent && isOnlineEvent) setSeminarType(SeminarType.ALL);
    else if (isLocalEvent && !isOnlineEvent) setSeminarType(SeminarType.LOCAL);
    else if (!isLocalEvent && isOnlineEvent) setSeminarType(SeminarType.ONLINE);
  }, [isLocalEvent, isOnlineEvent, setSeminarType]);

  return (
    <Stack flexDirection="column">
      <Typography sx={{ color: BLACK, fontSize: TOPIC_LABEL }}>
        {translate('seminar.detail.item.select_event')}
      </Typography>
      <Stack
        flexDirection="row"
        sx={{ marginTop: '12px', marginLeft: '12px' }}
        gap={2}
      >
        <Checkbox
          checked={isLocalEvent}
          label={translate('seminar.detail.item.venue_event')}
          onClick={handleClickLocalEventOptions}
        />
        <Checkbox
          checked={isOnlineEvent}
          label={translate('seminar.detail.item.web_event')}
          onClick={handleClickOnlineEventOptions}
        />
      </Stack>
    </Stack>
  );
};

export default EventTypeField;
