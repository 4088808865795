import { TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SECOND_LIGHT_GRAY } from '../../themes/colors';
import { TABLE_CELL } from '../../themes/fonts';
import { IParticipant } from '../../redux/seminars/types';
import { renderUserType, translate } from '../../libs/utils';

interface Props {
  data: IParticipant[];
}

const useStyles = makeStyles({
  tableCellText: {
    color: `${SECOND_LIGHT_GRAY} !important`,
    fontSize: `${TABLE_CELL} !important`,
  },
});

const TableBody = ({ data }: Props) => {
  const styles = useStyles();

  const handleIsAbsent = (isAbsent: boolean) => {
    if (isAbsent == true) {
      return translate('participant.detail.table.participant_type.onsite');
    }
    if (isAbsent == false) {
      return translate('participant.detail.table.participant_type.online');
    }
  };

  return (
    <>
      {data?.map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          <TableCell
            align="center"
            key="dental_registration_number"
            className={styles.tableCellText}
          >
            {row['dental_registration_number']}
          </TableCell>
          <TableCell align="center" key="area" className={styles.tableCellText}>
            {row['area']['area_name']}
          </TableCell>
          <TableCell
            align="center"
            key="kanji_name"
            className={styles.tableCellText}
          >
            {row['kanji_name']}
          </TableCell>
          <TableCell
            align="center"
            key="email"
            className={styles.tableCellText}
          >
            {row['email']}
          </TableCell>
          <TableCell
            align="center"
            key="user_type"
            className={styles.tableCellText}
          >
            {renderUserType(row['user_type'])}
          </TableCell>
          <TableCell
            align="center"
            key="is_absent"
            className={styles.tableCellText}
          >
            {handleIsAbsent(row['isAbsent'])}
          </TableCell>
          <TableCell
            align="center"
            key="birth_date"
            className={styles.tableCellText}
          >
            {row['birth_date']}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TableBody;
