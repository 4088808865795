import { FC, ReactNode } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MAIN_LIGHT_COLOR, WHITE } from '../../themes/colors';

interface Props {
  label: string;
  content: ReactNode;
  height: string;
}

const useStyles = makeStyles({
  container: {
    maxWidth: '1226',
    margin: '0 32px',
  },
  labelContainer: {
    minWidth: '240px',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  textLabel: {
    color: WHITE,
    fontSize: '14px !important',
    fontWeight: 'bold !important',
  },
});

const LabelField: FC<Props> = ({ label, content, height }) => {
  const styles = useStyles();
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={3}
      className={`${styles.container} ${height}`}
    >
      <Box className={styles.labelContainer}>
        <Typography className={styles.textLabel}>{label}</Typography>
      </Box>
      {content}
    </Stack>
  );
};

export default LabelField;
