/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSdk } from '../../libs/apis';
import { IQuestionnaire } from '../../libs/apis/questionnaires/types';
import { IQuestionnairesState, IQuestionnaireState } from './types';

export const getQuestionnaires = createAsyncThunk(
  '/admin/questionnaires',
  async (page: number, { rejectWithValue }) => {
    try {
      const data: IQuestionnairesState =
        await apiSdk.questionnaireApis.getQuestionnaires(page);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getQuestionnaire = createAsyncThunk(
  '/admin/questionnaires/detail',
  async (questionnaireId: number, { rejectWithValue }) => {
    try {
      const data: IQuestionnaireState =
        await apiSdk.questionnaireApis.getQuestionnaire(questionnaireId);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const createQuestionnaire = createAsyncThunk(
  '/admin/questionnaires/create',
  async (questionnaire: IQuestionnaire, { rejectWithValue }) => {
    try {
      const data: IQuestionnaireState =
        await apiSdk.questionnaireApis.createQuestionnaire(questionnaire);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const editQuestionnaire = createAsyncThunk(
  '/admin/questionnaires/edit',
  async (questionnaire: IQuestionnaire, { rejectWithValue }) => {
    try {
      const data: IQuestionnaireState =
        await apiSdk.questionnaireApis.editQuestionnaire(questionnaire);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
