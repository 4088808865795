import { makeStyles } from '@mui/styles';
import { IArea, INews } from '../../redux/news/types';
import { ORANGE, SECOND_LIGHT_GRAY } from '../../themes/colors';
import { TABLE_CELL } from '../../themes/fonts';
import { TableCell, TableRow } from '@mui/material';
import { renderUserType } from '../../libs/utils';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';

interface Props {
  data: INews[];
  onClickDelete: (newsId: number) => void;
}

const useStyles = makeStyles({
  tableCellText: {
    color: `${SECOND_LIGHT_GRAY} !important`,
    fontSize: `${TABLE_CELL} !important`,
  },

  icon: {
    color: ORANGE,
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
});

const TableBody = ({ data, onClickDelete }: Props) => {
  const styles = useStyles();

  const handleRenderTargetArea = (targetArea: IArea[]) => {
    const areaNames = targetArea.map((area: IArea) => area.area_name);
    return areaNames.join(', ');
  };

  return (
    <>
      {data?.map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          <TableCell
            align="center"
            key="title"
            className={styles.tableCellText}
          >
            {row['title']}
          </TableCell>
          <TableCell
            align="center"
            key="target_area"
            className={styles.tableCellText}
          >
            {handleRenderTargetArea(row['target_area'])}
          </TableCell>
          <TableCell
            align="center"
            key="user_type"
            className={styles.tableCellText}
          >
            {renderUserType(row['user_type'])}
          </TableCell>
          <TableCell
            align="center"
            key="delete"
            className={styles.tableCellText}
          >
            <RestoreFromTrashOutlinedIcon
              className={styles.icon}
              onClick={() => onClickDelete(row.news_id)}
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TableBody;
