import React from 'react';
import Header from './Header';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { BLACK } from '../themes/colors';
import { TITLE, TITLE_LINE_HEIGHT } from '../themes/fonts';

const useStyles = makeStyles({
  pageContainer: {
    margin: '60px auto 0',
  },

  titleContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
    padding: '14px 32px',
    marginBottom: '45px',
  },
});

interface LayoutProps {
  title?: string;
  maxWidth?: number;
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  children,
  title,
  maxWidth,
}) => {
  const styles = useStyles();
  return (
    <div>
      <Header />
      <Box
        component="main"
        className={styles.pageContainer}
        sx={{ maxWidth: maxWidth ? `${maxWidth}px` : '1315px' }}
      >
        {title && (
          <Box className={styles.titleContainer}>
            <Typography
              sx={{
                color: BLACK,
                fontSize: TITLE,
                lineHeight: TITLE_LINE_HEIGHT,
              }}
            >
              {title}
            </Typography>
          </Box>
        )}
        {children}
      </Box>
    </div>
  );
};

export default Layout;
