import React from 'react';
import { Stack, SxProps, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SECOND_LIGHT_GRAY } from '../themes/colors';
import { makeStyles } from '@mui/styles';

interface Props {
  label: string;
  sx?: SxProps;
  textSx?: SxProps;
  closeIcon?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles({
  chip: {
    cursor: 'pointer',
    padding: '2px 10px',
    borderRadius: '6px',
    height: '24px',

    '&:hover': {
      opacity: 0.8,
    },
  },

  textLabel: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxLines: 2,
  },
});

const Chip: React.FC<Props> = ({
  label,
  sx,
  textSx,
  closeIcon = true,
  onClick,
}) => {
  const styles = useStyles();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={sx}
      className={styles.chip}
      flexDirection="row"
    >
      <Typography sx={textSx} className={styles.textLabel}>
        {label}
      </Typography>
      {closeIcon && (
        <CloseIcon
          sx={{
            width: '14px',
            height: '14px',
            marginLeft: '4px',
            color: SECOND_LIGHT_GRAY,
          }}
          onClick={onClick}
        />
      )}
    </Stack>
  );
};

export default Chip;
