import { TableCell, TableRow } from '@mui/material';
import { IArea, ISeminars } from '../../redux/seminars/types';
import {
  MAIN_COLOR,
  MAIN_LIGHT_COLOR,
  SECOND_LIGHT_GRAY,
} from '../../themes/colors';
import { TABLE_CELL } from '../../themes/fonts';
import { makeStyles } from '@mui/styles';
import { getDate } from '../../libs/utils/formatDate';
import InfoIcon from '@mui/icons-material/Info';

interface Props {
  data: ISeminars[] | undefined;
  onClickDetail: (seminarId: number) => void;
}

const useStyles = makeStyles({
  tableCellText: {
    color: `${SECOND_LIGHT_GRAY} !important`,
    fontSize: `${TABLE_CELL} !important`,
  },

  icon: {
    color: MAIN_COLOR,
    '&:hover': {
      color: MAIN_LIGHT_COLOR,
      cursor: 'pointer',
    },
  },
});

const TableBody = ({ data, onClickDetail }: Props) => {
  const styles = useStyles();

  const handleRenderTargetArea = (targetArea: IArea[]) => {
    const areaNames = targetArea.map((area: IArea) => area.area_name);
    return areaNames.join(', ');
  };

  return (
    <>
      {data?.map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          <TableCell
            align="center"
            key="title"
            className={styles.tableCellText}
          >
            {row['title']}
          </TableCell>
          <TableCell
            align="center"
            key="organizer_name"
            className={styles.tableCellText}
          >
            {row['organizer_name']}
          </TableCell>
          <TableCell
            align="left"
            key="target_area"
            className={styles.tableCellText}
          >
            {handleRenderTargetArea(row['target_area'])}
          </TableCell>
          <TableCell
            align="center"
            key="apply_start_at"
            className={styles.tableCellText}
          >
            {getDate(row['apply_start_at'].toString())}
          </TableCell>
          <TableCell
            align="center"
            key="apply_end_at"
            className={styles.tableCellText}
          >
            {getDate(row['apply_start_at'].toString())}~
            {getDate(row['apply_end_at'].toString())}
          </TableCell>
          <TableCell
            align="center"
            key="detail_action"
            className={styles.tableCellText}
          >
            <InfoIcon
              className={styles.icon}
              onClick={() => onClickDetail(row.seminar_id)}
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
export default TableBody;
