/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, FC, useState } from 'react';
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LOGO from '../assets/images/logo.svg';
import PERSON from '../assets/icons/person.svg';
import TemporaryDrawer from './Drawer';
import LogoutIcon from '@mui/icons-material/Logout';
import { translate } from '../libs/utils';
import { GRAY } from '../themes/colors';
import { useAppDispatch } from '../redux';
import { logout } from '../redux/auth/action';
import { useSelector } from 'react-redux';
import { logoutSuccessSelector } from '../redux/auth/selectors';
import {
  getItemDataStorage,
  removeItemDataStorage,
} from '../libs/utils/localStorage';
import { TOKEN_KEYS, USER } from '../configs';

const Header: FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userInfo, setUserInfo] = useState<{
    admin_user_id: number;
    name: string;
    email: string;
    role: number;
    dental_registration_number: string;
    birth_date: Date;
    created_at: Date;
    updated_at: Date;
  } | null>(null);

  const dispatch = useAppDispatch();
  const isLogoutSuccess: boolean = useSelector(logoutSuccessSelector);

  const open = Boolean(anchorEl);
  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (isLogoutSuccess) {
      removeItemDataStorage(TOKEN_KEYS.ACCESS_TOKEN);
      removeItemDataStorage(TOKEN_KEYS.EXPIRES_IN);

      // eslint-disable-next-line no-undef
      window.location.href = '/login';
    }
  }, [isLogoutSuccess]);

  useEffect(() => {
    const userInfo: string | null = getItemDataStorage(USER);
    if (userInfo) setUserInfo(JSON.parse(userInfo));
    else {
      dispatch(logout());
    }
  }, [dispatch]);

  return (
    <AppBar sx={{ backgroundColor: 'white', height: '53px' }}>
      <Box
        sx={{
          padding: '0 8px !important',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <IconButton onClick={() => toggleDrawer(true)}>
            <MenuIcon color="primary" sx={{ fontSize: '40px' }} />
          </IconButton>
          <img src={LOGO} alt="LOGO" width="46px" />
        </Box>
        <Box sx={{ marginRight: '8px' }}>
          <IconButton onClick={handleClick}>
            <img src={PERSON} alt="PERSON" width="24px" />
          </IconButton>
          <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
            <MenuList sx={{ width: '220px' }}>
              <MenuItem sx={{ paddingY: 0 }}>
                <Typography>{userInfo?.name}</Typography>
              </MenuItem>
              <MenuItem sx={{ paddingY: 0 }}>
                <Typography variant="subtitle2" sx={{ color: GRAY }}>
                  {userInfo?.email}
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText onClick={handleClickLogout}>
                  {translate('header.logout')}
                </ListItemText>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
      <TemporaryDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
    </AppBar>
  );
};

export default Header;
