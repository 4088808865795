import { RootState } from '..';
import { IData, IError, IMembers } from './types';

export const membersSelector = (state: RootState): IData => state.member.data;

export const memberSelector = (state: RootState): IMembers => state.member.data;

export const errorsSelector = (state: RootState): IError => state.member.errors;

export const successSelector = (state: RootState): boolean =>
  state.member.success;
