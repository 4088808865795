import { Box } from '@mui/material';
import Layout from '../../components/Layout';
import { IHeader, translate } from '../../libs/utils';
import React, { useEffect, useState } from 'react';
import TableButton from '../../components/table/TableButton';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux';
import { deleteNews, getAllNews } from '../../redux/news/action';
import { IData, INews } from '../../redux/news/types';
import { useSelector } from 'react-redux';
import { allNewsSelector } from '../../redux/news/selectors';
import Pagination from '../../components/table/Pagination';
import { calculateTotalPage } from '../../libs/utils/calculateTotalPage';
import Table from '../../components/table/Table';
import TableBody from '../../components/news/TableBody';
import DeletePopup from '../../components/popup/DeletePopup';

const headers: IHeader[] = [
  { id: 'title', label: translate('news.list.table.title') },
  { id: 'target_area', label: translate('news.list.table.target_area') },
  { id: 'user_type', label: translate('news.list.table.member_type') },
  { id: 'delete', label: translate('news.list.table.delete') },
];

const NewsScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [allNews, setAllNews] = useState<INews[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isShowDeletePopup, setIsShowDeletePopup] = useState<boolean>(false);
  const [selectedNewsId, setSelectedNewsId] = useState<number>(0);

  const handleOnChangePage = (page: number) => setCurrentPage(page);

  const handleRedirectToCreatePage = () => navigate('create');

  const data: IData = useSelector(allNewsSelector);

  useEffect(() => {
    dispatch(getAllNews({ page: currentPage }));
  }, [dispatch, currentPage]);

  useEffect(() => {
    setAllNews(data.items);
    setCurrentPage(data.current_page);
    setTotal(data.total);
  }, [data.current_page, data.items, data.total]);

  const handleCloseDeletePopup = () => {
    setIsShowDeletePopup(false);
  };

  const handleClickDeleteNews = (newsId: number) => {
    setSelectedNewsId(newsId);
    setIsShowDeletePopup(true);
  };

  const handleConfirmDeleteNews = async () => {
    await dispatch(deleteNews({ newsId: selectedNewsId }));
    dispatch(getAllNews({ page: currentPage }));
    setIsShowDeletePopup(false);
  };

  return (
    <Layout title={translate('news.list.title')}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          bgcolor: 'background.paper',
          py: 2,
          borderRadius: 1,
          mb: '5px',
          mt: '-35px',
        }}
      >
        <TableButton
          label={translate('button.add')}
          onClick={handleRedirectToCreatePage}
        />
      </Box>
      <Table headers={headers}>
        <TableBody data={allNews} onClickDelete={handleClickDeleteNews} />
      </Table>
      <Pagination
        total={calculateTotalPage(total)}
        page={currentPage}
        onChangePage={handleOnChangePage}
      />
      <DeletePopup
        onConfirm={handleConfirmDeleteNews}
        onCancel={handleCloseDeletePopup}
        onClose={handleCloseDeletePopup}
        open={isShowDeletePopup}
      />
    </Layout>
  );
};

export default NewsScreen;
