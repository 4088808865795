import { Box, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FC } from 'react';
import { translate } from '../../libs/utils';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import { Dayjs } from 'dayjs';
import ErrorMessage from '../ErrorMessage';

interface Props {
  eventStartAt: Dayjs;
  setEventStartAt?: (date: Dayjs) => void;
  eventEndAt: Dayjs;
  setEventEndAt?: (date: Dayjs) => void;
  errorMessage?: string[] | undefined;
}

const OpeningDayFields: FC<Props> = ({
  eventStartAt,
  setEventStartAt,
  eventEndAt,
  setEventEndAt,
  errorMessage,
}) => {
  return (
    <Stack flexDirection={'column'}>
      <Stack flexDirection="row" alignItems="left" gap={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={translate('seminar.detail.item.start_date')}
            dateValue={eventStartAt}
            onChange={(date: Dayjs) => setEventStartAt?.(date)}
          />
          <TimePicker
            label={translate('seminar.detail.item.start_time')}
            value={eventStartAt}
            onChange={(time: Dayjs) => setEventStartAt?.(time)}
          />
          <TimePicker
            label={translate('seminar.detail.item.end_time')}
            value={eventEndAt}
            onChange={(time: Dayjs) => setEventEndAt?.(time)}
          />
        </LocalizationProvider>
      </Stack>
      {errorMessage && (
        <Box sx={{ marginTop: '14px' }}>
          <ErrorMessage
            message={errorMessage.length > 0 ? errorMessage[0] : ''}
          />
        </Box>
      )}
    </Stack>
  );
};

export default OpeningDayFields;
