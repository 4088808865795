import { Checkbox, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { translate } from '../../libs/utils';
import { BLACK } from '../../themes/colors';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { TOPIC_LABEL } from '../../themes/fonts';

interface Props {
  acceptBuddy: number;
  setAcceptBuddy: (isAccept: number) => void;
}

const AcceptBuddyField: FC<Props> = ({ acceptBuddy, setAcceptBuddy }) => {
  return (
    <Stack flexDirection="column">
      <Typography sx={{ color: BLACK, fontSize: TOPIC_LABEL }}>
        {translate('seminar.create.accept_buddy.label')}
      </Typography>
      <Stack
        flexDirection="row"
        sx={{ marginTop: '12px', marginLeft: '12px' }}
        gap={4}
      >
        <Stack flexDirection="row">
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon />}
            checked={acceptBuddy == 1}
            onClick={() => setAcceptBuddy(1)}
          />
          <Typography alignContent={'center'}>
            {translate('seminar.create.accept_buddy.allow')}
          </Typography>
        </Stack>
        <Stack flexDirection="row">
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon />}
            checked={acceptBuddy == 0}
            onClick={() => setAcceptBuddy(0)}
          />
          <Typography alignContent={'center'}>
            {translate('seminar.create.accept_buddy.dont_allow')}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AcceptBuddyField;
