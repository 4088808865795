/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import {
  Box,
  Grid2 as Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { IHeader, translate } from '../../libs/utils';
import { makeStyles } from '@mui/styles';
import { MAIN_LIGHT_COLOR, WHITE } from '../../themes/colors';
import Table from '../../components/table/Table';
import TableBody from '../../components/detail-participant/TableBody';
import Pagination from '../../components/table/Pagination';
import Button from '../../components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import EXPORT_CSV from '../../assets/icons/export-csv.svg';
import { useAppDispatch } from '../../redux';
import {
  exportParticipantsEachSeminarCsv,
  getParticipantsEachSeminar,
  getSeminar,
} from '../../redux/seminars/action';
import {
  exportCsvSelector,
  seminarWithParticipantsSelector,
} from '../../redux/seminars/selectors';
import { useSelector } from 'react-redux';
import {
  IArea,
  IParticipant,
  IParticipants,
  ISeminar,
} from '../../redux/seminars/types';
import { calculateTotalPage } from '../../libs/utils/calculateTotalPage';
import { TOPIC_LABEL } from '../../themes/fonts';
import { clearCsv } from '../../redux/seminars';

const useStyles = makeStyles({
  inputContainer: {
    height: '82px',
  },

  labelContainer: {
    width: 'calc(128 / 514 * 100%)',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },

  textLabel: {
    color: WHITE,
    fontSize: `${TOPIC_LABEL} !important`,
    fontWeight: 'bold !important',
  },
});

const headers: IHeader[] = [
  { id: 'id', label: translate('participant.detail.table.id') },
  {
    id: 'target_area',
    label: translate('participant.detail.table.target_area'),
  },
  { id: 'name', label: translate('participant.detail.table.name') },
  { id: 'email', label: translate('participant.detail.table.email') },
  {
    id: 'membership_type',
    label: translate('participant.detail.table.membership_type'),
  },
  {
    id: 'participate_type',
    label: translate('participant.detail.table.participate_type'),
  },
  {
    id: 'date_of_birth',
    label: translate('participant.detail.table.date_of_birth'),
  },
];

const DetailParticipantScreen: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [participants, setParticipants] = useState<IParticipant[]>([]);

  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const data: { participants: IParticipants; seminar: ISeminar } = useSelector(
    seminarWithParticipantsSelector,
  );

  const handleOnChangePage = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (id) {
      dispatch(getSeminar({ seminarId: parseInt(id) }));
      dispatch(
        getParticipantsEachSeminar({
          seminarId: parseInt(id),
          page: currentPage,
        }),
      );
    }
  }, [dispatch, currentPage, id]);

  useEffect(() => {
    setParticipants(data.participants.items);
    setCurrentPage(data.participants.current_page);
    setTotal(data.participants.total);
  }, [
    data.participants.current_page,
    data.participants.items,
    data.participants.total,
  ]);

  const handleRedirectBackButton = () => navigate('/participants');

  const exportSelector = useSelector(exportCsvSelector);

  const handleExportCsv = () => {
    if (id)
      dispatch(exportParticipantsEachSeminarCsv({ seminarId: parseInt(id) }));
  };

  const handleRenderTargetArea = (targetArea: IArea[]) => {
    const areaNames = targetArea.map((area: IArea) => area.area_name);
    return areaNames.join(', ');
  };

  useEffect(() => {
    if (exportSelector) {
      const url = window.URL.createObjectURL(new Blob([exportSelector]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = 'participants.csv';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(clearCsv());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportSelector]);

  return (
    <Layout maxWidth={1218}>
      <Grid
        container
        sx={{ paddingTop: '90px', marginBottom: '28px' }}
        spacing={3}
      >
        <Grid sx={{ width: 'calc(514 / 1218 * 100%)' }}>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={3}
            className={styles.inputContainer}
          >
            <Box className={styles.labelContainer}>
              <Typography className={styles.textLabel}>
                {translate('participant.detail.label.title')}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                sx={{ width: '100%' }}
                type="text"
                variant="standard"
                value={data.seminar.title}
              />
            </Box>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={3}
            className={styles.inputContainer}
          >
            <Box className={styles.labelContainer}>
              <Typography className={styles.textLabel}>
                {translate('participant.detail.label.organizer')}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                sx={{ width: '100%' }}
                type="text"
                variant="standard"
                value={data.seminar.organizer_name}
              />
            </Box>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={3}
            className={styles.inputContainer}
            sx={{ height: '170px' }}
          >
            <Box className={styles.labelContainer}>
              <Typography className={styles.textLabel}>
                {translate('participant.detail.label.target_area')}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, alignSelf: 'start' }}>
              <TextField
                sx={{ width: '100%' }}
                type="text"
                label="Label"
                variant="outlined"
                multiline
                maxRows={3}
                value={handleRenderTargetArea(data.seminar.target_area)}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid sx={{ flexGrow: 1 }}>
          <Stack
            flexDirection="row"
            alignItems="start"
            gap={3}
            sx={{ height: '100%' }}
          >
            <Box className={styles.labelContainer}>
              <Typography className={styles.textLabel}>
                {translate('participant.detail.label.detail_seminar')}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, marginTop: '8px' }}>
              <TextField
                sx={{ width: '100%' }}
                type="text"
                label="Label"
                variant="outlined"
                multiline
                maxRows={6}
                value={data.seminar.detail}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ paddingX: '18px' }}>
        <Table headers={headers}>
          <TableBody data={participants}></TableBody>
        </Table>
        <Stack flexDirection="row" alignItems="center">
          <Box sx={{ flexGrow: 1 }}>
            <Pagination
              total={calculateTotalPage(total)}
              page={currentPage}
              onChangePage={handleOnChangePage}
            />
          </Box>
          <IconButton sx={{ padding: '8px' }} onClick={handleExportCsv}>
            <img src={EXPORT_CSV} alt="EXPORT_CSV" width="44px" />
          </IconButton>
        </Stack>
        <Stack alignItems="end">
          <Button
            label={translate('participant.detail.button.back')}
            sx={{
              width: '100px',
              height: '38px',
              borderRadius: 0,
              boxShadow: 0,
            }}
            onClick={handleRedirectBackButton}
            textSx={{ fontSize: '14px', fontWeight: 'bold' }}
          />
        </Stack>
      </Box>
    </Layout>
  );
};

export default DetailParticipantScreen;
