import { AxiosInstance } from 'axios';
import { IQuestionnaire } from './types';
import {
  IQuestionnairesState,
  IQuestionnaireState,
} from '../../../redux/questionnaires/types';

export class QuestionnaireApi {
  constructor(private axiosInstance: AxiosInstance) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getQuestionnaires(page: number): Promise<IQuestionnairesState> {
    const { data } = await this.axiosInstance.get('/api/admin/questionnaires');
    return data;
  }

  async getQuestionnaire(
    questionnaireId: number,
  ): Promise<IQuestionnaireState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/questionnaires/${questionnaireId}`,
    );
    return data;
  }

  async createQuestionnaire(
    questionnaire: IQuestionnaire,
  ): Promise<IQuestionnaireState> {
    const { data } = await this.axiosInstance.post(
      `/api/admin/questionnaires/`,
      { ...questionnaire },
    );
    return data;
  }

  async editQuestionnaire(
    questionnaire: IQuestionnaire,
  ): Promise<IQuestionnaireState> {
    const { data } = await this.axiosInstance.put(
      `/api/admin/questionnaires/${questionnaire.questionnaire_id}`,
      { ...questionnaire },
    );
    return data;
  }
}
