/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICreateSeminar,
  IEditSeminar,
  ISeminarDetailLocal,
  ISeminarLocal,
  ISeminarWithParticipantsLocal,
} from '../../libs/apis/seminars/types';
import {
  ICreateSeminarState,
  IEditSeminarState,
  ISeminarDetailState,
  ISeminarsState,
  ISeminarWithParticipantsState,
} from './types';
import { apiSdk } from '../../libs/apis';

export const getSeminars = createAsyncThunk(
  '/admin/seminars',
  async (seminarData: ISeminarLocal, { rejectWithValue }) => {
    try {
      const data: ISeminarsState =
        await apiSdk.seminarApis.getSeminars(seminarData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getSeminar = createAsyncThunk(
  '/admin/seminars/detail',
  async (seminarData: ISeminarDetailLocal, { rejectWithValue }) => {
    try {
      const data: ISeminarDetailState =
        await apiSdk.seminarApis.getSeminar(seminarData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const createSeminar = createAsyncThunk(
  '/admin/seminars/create',
  async (seminarData: ICreateSeminar, { rejectWithValue }) => {
    try {
      const data: ICreateSeminarState =
        await apiSdk.seminarApis.createSeminar(seminarData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const editSeminar = createAsyncThunk(
  '/admin/seminars/edit',
  async (seminarData: IEditSeminar, { rejectWithValue }) => {
    try {
      const data: IEditSeminarState =
        await apiSdk.seminarApis.editSeminar(seminarData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getParticipantsEachSeminar = createAsyncThunk(
  '/admin/seminars/participants',
  async (seminarData: ISeminarWithParticipantsLocal, { rejectWithValue }) => {
    try {
      const data: ISeminarWithParticipantsState =
        await apiSdk.seminarApis.getParticipantsEachSeminar(seminarData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const exportParticipantsEachSeminarCsv = createAsyncThunk(
  '/admin/seminars/participants/export-csv',
  async (seminarData: ISeminarDetailLocal, { rejectWithValue }) => {
    try {
      const data: any =
        await apiSdk.seminarApis.exportParticipantsEachSeminarCsv(seminarData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
