import { Button as MButton, SxProps, Typography } from '@mui/material';
import React, { useCallback } from 'react';

interface Props {
  label: string;
  sx: SxProps;
  textSx: SxProps;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  onFocus?: () => void;
}

const Button: React.FC<Props> = ({
  label,
  sx,
  textSx,
  type = 'button',
  onClick,
  onFocus,
}) => {
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);
  return (
    <MButton
      type={type}
      onFocus={onFocus}
      onClick={() => handleOnClick()}
      sx={sx}
      variant="contained"
    >
      <Typography sx={textSx}>{label}</Typography>
    </MButton>
  );
};

export default Button;
