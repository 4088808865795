import { AxiosInstance } from 'axios';
import { ICreateNewsLocal, INewsDeleteLocal, INewsLocal } from './types';
import {
  ICreateNewsState,
  IDeleteNewsState,
  INewsState,
} from '../../../redux/news/types';

export class NewsApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async getAllNews(news: INewsLocal): Promise<INewsState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/news?page=${news.page}`,
    );
    return data;
  }

  async createNews(news: ICreateNewsLocal): Promise<ICreateNewsState> {
    const { data } = await this.axiosInstance.post('/api/admin/news', {
      title: news.title,
      user_type: news.user_type,
      url: news.url,
      is_push_noti: news.is_push_noti,
      all_areas: news.all_areas,
      target_area: news.target_area,
    });
    return data;
  }

  async deleteNews(news: INewsDeleteLocal): Promise<IDeleteNewsState> {
    const { data } = await this.axiosInstance.delete(
      `/api/admin/news/${news.newsId}`,
    );
    return data;
  }
}
