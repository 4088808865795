/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import { createNews, deleteNews, getAllNews } from './action';
import {
  ICreateNewsError,
  ICreateNewsState,
  IDeleteNewsState,
  INewsState,
} from './types';

interface IState {
  list: INewsState;
  create: ICreateNewsState;
  delete: IDeleteNewsState;
  errors?: ICreateNewsError;
}

const initValue = {
  news_id: 0,
  title: '',
  user_type: 0,
  url: '',
  is_push_noti: false,
  target_area: [],
  created_at: '',
};

const defaultState = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: initValue,
};

export const initialState: IState = {
  list: {
    message: '',
    success: false,
    status: HTTP_STATUS.UNAUTHORIZED,
    data: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0,
      items: [],
    },
  },
  create: defaultState,
  delete: {
    message: '',
    success: false,
    status: HTTP_STATUS.UNAUTHORIZED,
    data: null,
  },
};

const newsSlice = createGenericSlice({
  name: 'news',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = undefined;
    },

    clearCreateState: (state) => {
      state.create.message = '';
      state.create.success = false;
      state.create.data = initValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNews.rejected, (state, action: any) => {
      state.list.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.list.message = action.payload.message;
        state.list.success = action.payload.success;
      }
    });

    builder.addCase(
      getAllNews.fulfilled,
      (state, action: PayloadAction<INewsState>) => {
        state.list.success = action.payload.success;
        state.list.data = action.payload.data;
        state.list.status = action.payload.status;
      },
    );

    builder.addCase(createNews.rejected, (state, action: any) => {
      state.create.status = action.payload.status;
      state.create.success = action.payload.success;

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(
      createNews.fulfilled,
      (state, action: PayloadAction<ICreateNewsState>) => {
        state.create.success = action.payload.success;
        state.create.data = action.payload.data;
        state.create.status = action.payload.status;
      },
    );

    builder.addCase(deleteNews.rejected, (state, action: any) => {
      state.delete.status = action.payload.status;
      state.delete.message = action.payload.message;
      state.delete.success = action.payload.success;
    });

    builder.addCase(
      deleteNews.fulfilled,
      (state, action: PayloadAction<IDeleteNewsState>) => {
        state.delete.message = action.payload.message;
        state.delete.success = action.payload.success;
        state.delete.status = action.payload.status;
      },
    );
  },
});

export const { clearErrors, clearCreateState } = newsSlice.actions;

export default newsSlice.reducer;
