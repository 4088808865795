/* eslint-disable no-undef */
import {
  Stack,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { FC, useState } from 'react';
import { translate } from '../../libs/utils';
import { IArea } from '../../redux/seminars/types';
import {
  NOTICE,
  LIGHT_PURPLE,
  TYPOGRAPHY,
  LIGHT_GRAY,
} from '../../themes/colors';
import Chip from '../Chip';
import SelectAllAreasField from '../questionnaire/SelectAllAreasField';

interface Props {
  allAreas: IArea[];
  areas: IArea[];
  setAreas: (areas: IArea[]) => void;
  isSelectAllAreas: boolean;
  setIsSelectAllAreas: (isSelectAllAreas: boolean) => void;
}

const TargetAreasField: FC<Props> = ({
  allAreas,
  areas,
  setAreas,
  isSelectAllAreas,
  setIsSelectAllAreas,
}) => {
  const [selectedArea, setSelectedArea] = useState<string>('');

  const handleOnSelectTargetArea = (event: SelectChangeEvent) => {
    const area = allAreas?.find(
      (item) => item.area_id == parseInt(event.target.value),
    );

    if (area && !areas.find((item) => item.area_id == area.area_id)) {
      setAreas([...areas, area]);
    }

    setSelectedArea(event.target.value);
  };

  const handleRemoveArea = (areaId: number) => {
    const filteredAreas: IArea[] = areas.filter(
      (area: IArea) => area.area_id !== areaId,
    );

    if (isSelectAllAreas) setIsSelectAllAreas(false);
    setAreas([...filteredAreas]);
  };

  const handleSetIsSelectAllAreas = () => {
    if (!isSelectAllAreas) {
      setAreas([...allAreas]);
      setIsSelectAllAreas(true);
      return;
    }

    if (isSelectAllAreas) {
      setAreas([]);
      setIsSelectAllAreas(false);
      return;
    }
  };

  const renderDropDownSelectTargetArea = () => {
    return (
      <Box sx={{ width: '200px' }}>
        <Typography sx={{ fontSize: '12px', color: NOTICE, fontWeight: '600' }}>
          {translate('seminar.create.target_area.specific_area')}
        </Typography>
        <FormControl fullWidth sx={{ marginTop: '12px' }}>
          <InputLabel>
            {translate('seminar.create.label.target_area.select')}
          </InputLabel>
          <Select
            value={selectedArea}
            label={translate('seminar.create.label.target_area.select')}
            onChange={handleOnSelectTargetArea}
          >
            {allAreas.map((area: IArea) => (
              <MenuItem key={area.area_id} value={area.area_id}>
                {area.area_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SelectAllAreasField
          isSelectAllAreas={isSelectAllAreas}
          setIsSelectAllAreas={handleSetIsSelectAllAreas}
        />
      </Box>
    );
  };

  return (
    <Stack flexDirection="row" gap={2} sx={{ flexGrow: 1 }}>
      {renderDropDownSelectTargetArea()}
      <Stack
        width={'765px'}
        alignContent="flex-start"
        flexWrap="wrap"
        flexDirection="row"
        gap={1}
        sx={{
          flexGrow: 1,
          backgroundColor: LIGHT_GRAY,
          borderRadius: '5px',
          padding: '10px',
          height: '140px',
        }}
      >
        {areas.map((area) => (
          <Chip
            key={area.area_id}
            label={area.area_name}
            onClick={() => handleRemoveArea(area.area_id)}
            sx={{
              backgroundColor: LIGHT_PURPLE,
              width: '80px',
              height: '22px',
            }}
            textSx={{
              color: TYPOGRAPHY,
              fontSize: '11px',
              fontWeight: '500',
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default TargetAreasField;
