/* eslint-disable @typescript-eslint/no-explicit-any */
import { RootState } from '..';
import {
  IArea,
  IData,
  IParticipants,
  ISeminar,
  ICreateSeminarError,
  IEditSeminarError,
} from './types';

export const seminarsSelector = (state: RootState): IData =>
  state.seminar.list.data;

export const seminarSelector = (state: RootState): ISeminar =>
  state.seminar.detail.data;

export const targetAreaSelector = (state: RootState): IArea[] =>
  state.seminar.detail.data.target_area;

export const errorsSelector = (
  state: RootState,
): ICreateSeminarError | IEditSeminarError | undefined => state.seminar.errors;

export const statusSelector = (state: RootState): number | undefined =>
  state.seminar.create?.status;

export const successSelector = (state: RootState): boolean =>
  state.seminar.create.success;

export const editStatusSelector = (state: RootState): number | undefined =>
  state.seminar.edit.status;

export const editSuccessSelector = (state: RootState): boolean =>
  state.seminar.edit.success;

export const seminarWithParticipantsSelector = (
  state: RootState,
): { participants: IParticipants; seminar: ISeminar } =>
  state.seminar.detailWithParticipants.data;

export const exportCsvSelector = (state: RootState): any =>
  state.seminar.exportCsv.data;
