import { TableCell, TableRow, Typography } from '@mui/material';
import {
  MAIN_LIGHT_COLOR,
  SECOND_LIGHT_GRAY,
  MAIN_COLOR,
} from '../../themes/colors';
import { TABLE_CELL } from '../../themes/fonts';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IMembers } from '../../redux/members/types';
import { translate } from '../../libs/utils/translate';
import { makeStyles } from '@mui/styles';
import { renderUserType } from '../../libs/utils';

interface Props {
  data: IMembers[] | undefined;
  onClickEdit: (memberId: number) => void;
  onClickDelete: (memberId: number) => void;
}

const useStyles = makeStyles({
  icon: {
    color: MAIN_COLOR,
    '&:hover': {
      color: MAIN_LIGHT_COLOR,
      cursor: 'pointer',
    },
  },
});

const TableBody = ({ data, onClickEdit, onClickDelete }: Props) => {
  const classes = useStyles();

  if (!data?.length) {
    return (
      <TableRow>
        <TableCell
          colSpan={8}
          align="center"
          sx={{
            borderBottom: 'none',
            color: SECOND_LIGHT_GRAY,
            fontSize: TABLE_CELL,
          }}
        >
          <Typography>{translate('member.list.table.not_found')}</Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {data?.map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          <TableCell
            align="center"
            key="kanji_name"
            sx={{ color: SECOND_LIGHT_GRAY, fontSize: TABLE_CELL }}
          >
            {row.kanji_name}
          </TableCell>
          <TableCell
            align="center"
            key="furigana_name"
            sx={{ color: SECOND_LIGHT_GRAY, fontSize: TABLE_CELL }}
          >
            {row.furigana_name}
          </TableCell>
          <TableCell
            align="center"
            key="dental_registration_number"
            sx={{ color: SECOND_LIGHT_GRAY, fontSize: TABLE_CELL }}
          >
            {row.dental_registration_number}
          </TableCell>
          <TableCell
            align="center"
            key="email_address"
            sx={{ color: SECOND_LIGHT_GRAY, fontSize: TABLE_CELL }}
          >
            {row['email']}
          </TableCell>
          <TableCell
            align="center"
            key="user_type"
            sx={{ color: SECOND_LIGHT_GRAY, fontSize: TABLE_CELL }}
          >
            {renderUserType(row['user_type'])}
          </TableCell>
          <TableCell
            align="center"
            key="area_name"
            sx={{ color: SECOND_LIGHT_GRAY, fontSize: TABLE_CELL }}
          >
            {row.area?.area_name ?? 'area_name'}
          </TableCell>
          <TableCell
            align="center"
            key="birth_date"
            sx={{ color: SECOND_LIGHT_GRAY, fontSize: TABLE_CELL }}
          >
            {row.birth_date.toString()}
          </TableCell>
          <TableCell
            align="center"
            key="edit_action"
            sx={{ color: SECOND_LIGHT_GRAY, fontSize: TABLE_CELL }}
          >
            <EditIcon
              className={classes.icon}
              onClick={() => onClickEdit(row.user_id)}
            />
          </TableCell>
          <TableCell
            align="center"
            key="delete_action"
            sx={{ color: SECOND_LIGHT_GRAY, fontSize: TABLE_CELL }}
          >
            <DeleteOutlineIcon
              className={classes.icon}
              onClick={() => onClickDelete(row.user_id)}
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
export default TableBody;
