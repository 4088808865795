import { combineReducers } from 'redux';

import appReducer from './app';
import authReducer from './auth';
import areaReducer from './areas';
import memberReducer from './members';
import notificationReducer from './notifications';
import newsReducer from './news';
import safetyReducer from './safety';
import seminarReducer from './seminars';
import participantReducer from './participants';
import adminUserReducer from './admin-users';
import questionnaireReducer from './questionnaires';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  area: areaReducer,
  member: memberReducer,
  notification: notificationReducer,
  news: newsReducer,
  safety: safetyReducer,
  seminar: seminarReducer,
  participant: participantReducer,
  adminUser: adminUserReducer,
  questionnaire: questionnaireReducer,
});

export default rootReducer;
