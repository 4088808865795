/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import {
  createMember,
  getDetailMember,
  getMembers,
  updateMember,
} from './action';
import { ICreateMemberState, IMembersState, IMemberState } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

export const initialState: any = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
    members: [],
  },
};

const memberSlice = createGenericSlice({
  name: 'member',
  initialState,
  reducers: {
    clearState: (state) => {
      state.data = {};
      state.success = false;
      state.errors = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMembers.rejected, (state, action: any) => {
      state.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.message = action.payload.message;
        state.success = action.payload.success;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.status = action.payload.status;
      }
    });

    builder.addCase(
      getMembers.fulfilled,
      (state, action: PayloadAction<IMembersState>) => {
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.status = action.payload.status;
      },
    );

    builder.addCase(getDetailMember.rejected, (state, action: any) => {
      state.status = action.payload.status;
      state.success = action.payload.success;
    });

    builder.addCase(
      getDetailMember.fulfilled,
      (state, action: PayloadAction<IMemberState>) => {
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.status = action.payload.status;
      },
    );

    builder.addCase(createMember.rejected, (state, action: any) => {
      state.status = action.payload.status;
      state.success = action.payload.success;

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(
      createMember.fulfilled,
      (state, action: PayloadAction<ICreateMemberState>) => {
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.status = action.payload.status;
      },
    );

    builder.addCase(updateMember.rejected, (state, action: any) => {
      state.status = action.payload.status;
      state.success = action.payload.success;

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(
      updateMember.fulfilled,
      (state, action: PayloadAction<ICreateMemberState>) => {
        state.success = action.payload.success;
        state.status = action.payload.status;
      },
    );
  },
});

export { getMembers, getDetailMember, createMember, updateMember };
export const { clearState } = memberSlice.actions;
export default memberSlice.reducer;
